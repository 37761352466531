import { defineStore } from 'pinia'
import router from '@/router/router'

const isStaging = window.location.href.includes('staging.')

const versionString
  = import.meta.env.MODE === 'production'
    ? `${import.meta.env.VITE_APP_VERSION}${isStaging ? '-staging' : ''}`
    : `${import.meta.env.VITE_APP_VERSION}-${import.meta.env.MODE}`

const releaseString = import.meta.env.VITE_APP_BUILD_EPOCH
  ? new Date(Number(import.meta.env.VITE_APP_BUILD_EPOCH))
  : undefined

interface GlobalModal {
  show: boolean
  title?: string
  description?: string
  closeEvent?: (canceled: boolean) => void
  submitEvent?: () => void
  submitText?: string
  closeBtnVisible?: boolean
  loading?: boolean
}

export const useUtilitiesStore = defineStore('UtilitiesStore', {
  state: () => ({
    debug: import.meta.env.MODE === 'development',
    version: versionString,
    releaseDate: releaseString,
    alertMessage: null as null | { text: string, error?: boolean },
    globalModal: null as null | GlobalModal,
    isStaging,
  }),
  actions: {
    initEmailSend(email: string) {
      window.location.href = `mailto:${email}`
    },
    makeFormData(data: { [key: string]: any }) {
      const formData = new FormData()
      Object.keys(data).forEach((key) => {
        if (data[key] !== '' && data[key] !== undefined) {
          if (Array.isArray(data[key])) {
            // Check if it's an array of objects or primitives
            if (data[key].length > 0 && typeof data[key][0] === 'object') {
              // Serialize the array of objects as a string
              formData.append(key, JSON.stringify(data[key]))
            }
            else {
              // Append each item in the array of primitives individually
              // with explicit index
              data[key].forEach((item: any, index: number) => {
                formData.append(`${key}[${index}]`, item)
              })
            }
          }
          else if (typeof data[key] === 'object') {
            // Serialize single objects
            formData.append(key, JSON.stringify(data[key]))
          }
          else {
            // Directly append primitive types
            formData.append(key, data[key])
          }
        }
      })
      return formData
    },
    mergeObjectsWithoutNewProperties(obj1: any, obj2: any) {
      for (const key in obj2) {
        if (key in obj1)
          obj1[key] = typeof obj1[key] === 'object' && typeof obj2[key] === 'object' ? this.mergeObjectsWithoutNewProperties(obj1[key], obj2[key]) : obj2[key]
      }
      return obj1
    },
    async redirectToParent() {
      // Get the current path
      const path = router.currentRoute.value.path

      // Split the path into segments
      const segments = path.split('/')

      // Remove the last segment
      segments.pop()

      // Join the segments back into a path
      const parentPath = segments.join('/')

      // Redirect to the parent path
      await router.push(parentPath)
    },
  },
})
