import _definePage_default_0 from '/app/src/pages/admin/index/bds/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/app/src/pages/admin/index/enterprise/awards/index.vue?definePage&vue'
import _definePage_default_3 from '/app/src/pages/admin/index/enterprise/grants/index.vue?definePage&vue'
import _definePage_default_4 from '/app/src/pages/admin/index/enterprise/replicator/index.vue?definePage&vue'
import _definePage_default_5 from '/app/src/pages/admin/index/enterprise/starter/index.vue?definePage&vue'
import _definePage_default_6 from '/app/src/pages/admin/index/users/index.vue?definePage&vue'
import _definePage_default_7 from '/app/src/pages/admin/index.vue?definePage&vue'
import _definePage_default_8 from '/app/src/pages/authenticated/dashboard/guides/measurable-targets.vue?definePage&vue'
import _definePage_default_9 from '/app/src/pages/authenticated/dashboard/my-enterprise/index.vue?definePage&vue'
import _definePage_default_10 from '/app/src/pages/authenticated/dashboard/my-enterprise/invites/index.vue?definePage&vue'
import _definePage_default_11 from '/app/src/pages/authenticated/settings/index.vue?definePage&vue'

export const routes = [
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/app/src/pages/shared/[...all].vue'),
    /* no children */
    meta: {
      "layout": "blank"
    }
  },
  {
    path: '/admin',
    /* internal name: '/admin' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'Admin Dashboard',
        component: () => import('/app/src/pages/admin/index.vue'),
        children: [
          {
            path: 'bds',
            /* internal name: '/admin//bds' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'BDS+',
                component: () => import('/app/src/pages/admin/index/bds/index.vue'),
                /* no children */
                meta: {
                  "middlewares": [
                    "isAdmin"
                  ]
                }
              },
  _definePage_default_0
  ),
              {
                path: 'applications',
                /* internal name: '/admin//bds/applications' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'Bds applications overview',
                    component: () => import('/app/src/pages/admin/index/bds/applications/index.vue'),
                    /* no children */
                    meta: {
                      "breadcrumbsName": "Applications overview",
                      "layout": "admin",
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  }
                ],
                meta: {
                  "middlewares": [
                    "isAdmin"
                  ]
                }
              },
              {
                path: 'juror-assignment',
                /* internal name: '/admin//bds/juror-assignment' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'Bds juror assignment',
                    component: () => import('/app/src/pages/admin/index/bds/juror-assignment/index.vue'),
                    /* no children */
                    meta: {
                      "breadcrumbsName": "Juror assignment",
                      "layout": "admin",
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  }
                ],
                meta: {
                  "middlewares": [
                    "isAdmin"
                  ]
                }
              },
              {
                path: 'programmes',
                /* internal name: '/admin//bds/programmes' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'Bds programs',
                    component: () => import('/app/src/pages/admin/index/bds/programmes/index.vue'),
                    /* no children */
                    meta: {
                      "breadcrumbsName": "Programmes",
                      "layout": "admin",
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
                  {
                    path: ':id',
                    /* internal name: '/admin//bds/programmes/[id]' */
                    /* no component */
                    children: [
                      {
                        path: 'edit',
                        name: 'Edit bds program',
                        component: () => import('/app/src/pages/admin/index/bds/programmes/[id]/edit.vue'),
                        props: true,
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Edit program",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
                  {
                    path: 'create',
                    name: 'New bds program',
                    component: () => import('/app/src/pages/admin/index/bds/programmes/create.vue'),
                    /* no children */
                    meta: {
                      "breadcrumbsName": "New programme",
                      "layout": "admin",
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  }
                ],
                meta: {
                  "middlewares": [
                    "isAdmin"
                  ]
                }
              }
            ],
            meta: {
              "middlewares": [
                "isAdmin"
              ]
            }
          },
          {
            path: 'enterprise',
            /* internal name: '/admin//enterprise' */
            /* no component */
            children: [
              {
                path: 'awards',
                /* internal name: '/admin//enterprise/awards' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'Awards',
                    component: () => import('/app/src/pages/admin/index/enterprise/awards/index.vue'),
                    /* no children */
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
  _definePage_default_2
  ),
                  {
                    path: 'applications',
                    /* internal name: '/admin//enterprise/awards/applications' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Awards applications overview',
                        component: () => import('/app/src/pages/admin/index/enterprise/awards/applications/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Applications overview",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
                  {
                    path: 'juror-assignment',
                    /* internal name: '/admin//enterprise/awards/juror-assignment' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Awards juror assignment',
                        component: () => import('/app/src/pages/admin/index/enterprise/awards/juror-assignment/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Juror assignment",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
                  {
                    path: 'programmes',
                    /* internal name: '/admin//enterprise/awards/programmes' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Awards programs',
                        component: () => import('/app/src/pages/admin/index/enterprise/awards/programmes/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Programmes",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      },
                      {
                        path: ':id',
                        /* internal name: '/admin//enterprise/awards/programmes/[id]' */
                        /* no component */
                        children: [
                          {
                            path: 'edit',
                            name: 'Edit awards program',
                            component: () => import('/app/src/pages/admin/index/enterprise/awards/programmes/[id]/edit.vue'),
                            props: true,
                            /* no children */
                            meta: {
                              "breadcrumbsName": "Edit program",
                              "layout": "admin",
                              "middlewares": [
                                "isAdmin"
                              ]
                            }
                          }
                        ],
                        meta: {
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      },
                      {
                        path: 'create',
                        name: 'New awards program',
                        component: () => import('/app/src/pages/admin/index/enterprise/awards/programmes/create.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "New programme",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  }
                ],
                meta: {
                  "middlewares": [
                    "isAdmin"
                  ]
                }
              },
              {
                path: 'grants',
                /* internal name: '/admin//enterprise/grants' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'Grants',
                    component: () => import('/app/src/pages/admin/index/enterprise/grants/index.vue'),
                    /* no children */
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
  _definePage_default_3
  ),
                  {
                    path: 'applications',
                    /* internal name: '/admin//enterprise/grants/applications' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Grants applications overview',
                        component: () => import('/app/src/pages/admin/index/enterprise/grants/applications/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Applications overview",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
                  {
                    path: 'juror-assignment',
                    /* internal name: '/admin//enterprise/grants/juror-assignment' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Grants juror assignment',
                        component: () => import('/app/src/pages/admin/index/enterprise/grants/juror-assignment/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Juror assignment",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
                  {
                    path: 'programmes',
                    /* internal name: '/admin//enterprise/grants/programmes' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Grants programs',
                        component: () => import('/app/src/pages/admin/index/enterprise/grants/programmes/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Programmes",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      },
                      {
                        path: ':id',
                        /* internal name: '/admin//enterprise/grants/programmes/[id]' */
                        /* no component */
                        children: [
                          {
                            path: 'edit',
                            name: 'Edit grants program',
                            component: () => import('/app/src/pages/admin/index/enterprise/grants/programmes/[id]/edit.vue'),
                            props: true,
                            /* no children */
                            meta: {
                              "breadcrumbsName": "Edit program",
                              "layout": "admin",
                              "middlewares": [
                                "isAdmin"
                              ]
                            }
                          }
                        ],
                        meta: {
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      },
                      {
                        path: 'create',
                        name: 'New grants program',
                        component: () => import('/app/src/pages/admin/index/enterprise/grants/programmes/create.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "New programme",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  }
                ],
                meta: {
                  "middlewares": [
                    "isAdmin"
                  ]
                }
              },
              {
                path: 'replicator',
                /* internal name: '/admin//enterprise/replicator' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'Replicator',
                    component: () => import('/app/src/pages/admin/index/enterprise/replicator/index.vue'),
                    /* no children */
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
  _definePage_default_4
  ),
                  {
                    path: 'applications',
                    /* internal name: '/admin//enterprise/replicator/applications' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Replicator applications overview',
                        component: () => import('/app/src/pages/admin/index/enterprise/replicator/applications/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Applications overview",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
                  {
                    path: 'juror-assignment',
                    /* internal name: '/admin//enterprise/replicator/juror-assignment' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Replicator juror assignment',
                        component: () => import('/app/src/pages/admin/index/enterprise/replicator/juror-assignment/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Juror assignment",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
                  {
                    path: 'programmes',
                    /* internal name: '/admin//enterprise/replicator/programmes' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Replicator programs',
                        component: () => import('/app/src/pages/admin/index/enterprise/replicator/programmes/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Programmes",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      },
                      {
                        path: ':id',
                        /* internal name: '/admin//enterprise/replicator/programmes/[id]' */
                        /* no component */
                        children: [
                          {
                            path: 'edit',
                            name: 'Edit replicator program',
                            component: () => import('/app/src/pages/admin/index/enterprise/replicator/programmes/[id]/edit.vue'),
                            props: true,
                            /* no children */
                            meta: {
                              "breadcrumbsName": "Edit program",
                              "layout": "admin",
                              "middlewares": [
                                "isAdmin"
                              ]
                            }
                          }
                        ],
                        meta: {
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      },
                      {
                        path: 'create',
                        name: 'New replicator program',
                        component: () => import('/app/src/pages/admin/index/enterprise/replicator/programmes/create.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "New programme",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  }
                ],
                meta: {
                  "middlewares": [
                    "isAdmin"
                  ]
                }
              },
              {
                path: 'starter',
                /* internal name: '/admin//enterprise/starter' */
                /* no component */
                children: [
  _mergeRouteRecord(
                  {
                    path: '',
                    name: 'Starter',
                    component: () => import('/app/src/pages/admin/index/enterprise/starter/index.vue'),
                    /* no children */
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
  _definePage_default_5
  ),
                  {
                    path: 'applications',
                    /* internal name: '/admin//enterprise/starter/applications' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Starter applications overview',
                        component: () => import('/app/src/pages/admin/index/enterprise/starter/applications/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Applications overview",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
                  {
                    path: 'juror-assignment',
                    /* internal name: '/admin//enterprise/starter/juror-assignment' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Starter juror assignment',
                        component: () => import('/app/src/pages/admin/index/enterprise/starter/juror-assignment/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Juror assignment",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  },
                  {
                    path: 'programmes',
                    /* internal name: '/admin//enterprise/starter/programmes' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Starter programs',
                        component: () => import('/app/src/pages/admin/index/enterprise/starter/programmes/index.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "Programmes",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      },
                      {
                        path: ':id',
                        /* internal name: '/admin//enterprise/starter/programmes/[id]' */
                        /* no component */
                        children: [
                          {
                            path: 'edit',
                            name: 'Edit starter program',
                            component: () => import('/app/src/pages/admin/index/enterprise/starter/programmes/[id]/edit.vue'),
                            props: true,
                            /* no children */
                            meta: {
                              "breadcrumbsName": "Edit program",
                              "layout": "admin",
                              "middlewares": [
                                "isAdmin"
                              ]
                            }
                          }
                        ],
                        meta: {
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      },
                      {
                        path: 'create',
                        name: 'New starter program',
                        component: () => import('/app/src/pages/admin/index/enterprise/starter/programmes/create.vue'),
                        /* no children */
                        meta: {
                          "breadcrumbsName": "New programme",
                          "layout": "admin",
                          "middlewares": [
                            "isAdmin"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  }
                ],
                meta: {
                  "middlewares": [
                    "isAdmin"
                  ]
                }
              }
            ],
            meta: {
              "middlewares": [
                "isAdmin"
              ]
            }
          },
          {
            path: 'users',
            /* internal name: '/admin//users' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/admin//users/',
                component: () => import('/app/src/pages/admin/index/users/index.vue'),
                /* no children */
                meta: {
                  "middlewares": [
                    "isAdmin"
                  ]
                }
              },
  _definePage_default_6
  ),
              {
                path: 'manage',
                /* internal name: '/admin//users/manage' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'Users management',
                    component: () => import('/app/src/pages/admin/index/users/manage/index.vue'),
                    /* no children */
                    meta: {
                      "layout": "admin",
                      "middlewares": [
                        "isAdmin"
                      ]
                    }
                  }
                ],
                meta: {
                  "middlewares": [
                    "isAdmin"
                  ]
                }
              }
            ],
            meta: {
              "middlewares": [
                "isAdmin"
              ]
            }
          }
        ],
        meta: {
          "middlewares": [
            "isAdmin"
          ]
        }
      },
  _definePage_default_7
  ),
      {
        path: ':all(.*)',
        name: '/admin/[...all]',
        component: () => import('/app/src/pages/admin/[...all].vue'),
        /* no children */
        meta: {
          "layout": "blank",
          "middlewares": [
            "isAdmin"
          ]
        }
      }
    ],
    meta: {
      "middlewares": [
        "isAdmin"
      ]
    }
  },
  {
    path: '/dashboard',
    /* internal name: '/dashboard' */
    /* no component */
    children: [
      {
        path: '',
        name: 'Enterprise Dashboard',
        component: () => import('/app/src/pages/authenticated/dashboard/index.vue'),
        /* no children */
        meta: {
          "layout": "userDashboard",
          "middlewares": [
            "isEnterpriseUser"
          ]
        }
      },
      {
        path: ':type',
        /* internal name: '/dashboard/[type]' */
        /* no component */
        children: [
          {
            path: 'programmes',
            /* internal name: '/dashboard/[type]/programmes' */
            /* no component */
            children: [
              {
                path: ':id',
                /* internal name: '/dashboard/[type]/programmes/[id]' */
                /* no component */
                children: [
                  {
                    path: 'apply',
                    /* internal name: '/dashboard/[type]/programmes/[id]/apply' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: 'Application for enterprise programme',
                        component: () => import('/app/src/pages/authenticated/dashboard/[type]/programmes/[id]/apply/index.vue'),
                        props: true,
                        /* no children */
                        meta: {
                          "layout": "userDashboard",
                          "middlewares": [
                            "isEnterpriseUser"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isEnterpriseUser"
                      ]
                    }
                  },
                  {
                    path: 'upload-files',
                    /* internal name: '/dashboard/[type]/programmes/[id]/upload-files' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/dashboard/[type]/programmes/[id]/upload-files/',
                        component: () => import('/app/src/pages/authenticated/dashboard/[type]/programmes/[id]/upload-files/index.vue'),
                        props: true,
                        /* no children */
                        meta: {
                          "layout": "userDashboard",
                          "middlewares": [
                            "isEnterpriseUser"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isEnterpriseUser"
                      ]
                    }
                  },
                  {
                    path: 'view',
                    name: 'View application',
                    component: () => import('/app/src/pages/authenticated/dashboard/[type]/programmes/[id]/view.vue'),
                    props: true,
                    /* no children */
                    meta: {
                      "layout": "userDashboard",
                      "middlewares": [
                        "isEnterpriseUser"
                      ]
                    }
                  }
                ],
                meta: {
                  "middlewares": [
                    "isEnterpriseUser"
                  ]
                }
              }
            ],
            meta: {
              "middlewares": [
                "isEnterpriseUser"
              ]
            }
          }
        ],
        meta: {
          "middlewares": [
            "isEnterpriseUser"
          ]
        }
      },
      {
        path: 'guides',
        /* internal name: '/dashboard/guides' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'measurable-targets',
            name: '/dashboard/guides/measurable-targets',
            component: () => import('/app/src/pages/authenticated/dashboard/guides/measurable-targets.vue'),
            /* no children */
            meta: {
              "middlewares": [
                "isEnterpriseUser"
              ]
            }
          },
  _definePage_default_8
  )
        ],
        meta: {
          "middlewares": [
            "isEnterpriseUser"
          ]
        }
      },
      {
        path: 'my-applications',
        /* internal name: '/dashboard/my-applications' */
        /* no component */
        children: [
          {
            path: '',
            name: '/dashboard/my-applications/',
            component: () => import('/app/src/pages/authenticated/dashboard/my-applications/index.vue'),
            /* no children */
            meta: {
              "layout": "userDashboard",
              "middlewares": [
                "isEnterpriseUser"
              ]
            }
          }
        ],
        meta: {
          "middlewares": [
            "isEnterpriseUser"
          ]
        }
      },
      {
        path: 'my-enterprise',
        /* internal name: '/dashboard/my-enterprise' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/dashboard/my-enterprise/',
            component: () => import('/app/src/pages/authenticated/dashboard/my-enterprise/index.vue'),
            /* no children */
            meta: {
              "middlewares": [
                "isEnterpriseUser"
              ]
            }
          },
  _definePage_default_9
  ),
          {
            path: 'invites',
            /* internal name: '/dashboard/my-enterprise/invites' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: '/dashboard/my-enterprise/invites/',
                component: () => import('/app/src/pages/authenticated/dashboard/my-enterprise/invites/index.vue'),
                /* no children */
                meta: {
                  "middlewares": [
                    "isEnterpriseUser"
                  ]
                }
              },
  _definePage_default_10
  )
            ],
            meta: {
              "middlewares": [
                "isEnterpriseUser"
              ]
            }
          }
        ],
        meta: {
          "middlewares": [
            "isEnterpriseUser"
          ]
        }
      }
    ],
    meta: {
      "middlewares": [
        "isEnterpriseUser"
      ]
    }
  },
  {
    path: '/homepage',
    /* internal name: '/homepage' */
    /* no component */
    alias: ["/"],
    children: [
      {
        path: '',
        name: '/homepage/',
        component: () => import('/app/src/pages/shared/homepage/index.vue'),
        /* no children */
        meta: {
          "layout": "blank"
        }
      }
    ],
  },
  {
    path: '/jurors-dashboard',
    /* internal name: '/jurors-dashboard' */
    /* no component */
    children: [
      {
        path: '',
        name: '/jurors-dashboard/',
        component: () => import('/app/src/pages/authenticated/jurors-dashboard/index.vue'),
        /* no children */
        meta: {
          "layout": "userDashboard",
          "middlewares": [
            "isJuror"
          ]
        }
      },
      {
        path: ':type',
        /* internal name: '/jurors-dashboard/[type]' */
        /* no component */
        children: [
          {
            path: 'programmes',
            /* internal name: '/jurors-dashboard/[type]/programmes' */
            /* no component */
            children: [
              {
                path: ':programId',
                /* internal name: '/jurors-dashboard/[type]/programmes/[programId]' */
                /* no component */
                children: [
                  {
                    path: ':round',
                    /* internal name: '/jurors-dashboard/[type]/programmes/[programId]/[round]' */
                    /* no component */
                    children: [
                      {
                        path: '',
                        name: '/jurors-dashboard/[type]/programmes/[programId]/[round]/',
                        component: () => import('/app/src/pages/authenticated/jurors-dashboard/[type]/programmes/[programId]/[round]/index.vue'),
                        props: true,
                        /* no children */
                        meta: {
                          "layout": "userDashboard",
                          "middlewares": [
                            "isJuror"
                          ]
                        }
                      },
                      {
                        path: ':applicationId',
                        /* internal name: '/jurors-dashboard/[type]/programmes/[programId]/[round]/[applicationId]' */
                        /* no component */
                        children: [
                          {
                            path: 'application',
                            name: '/jurors-dashboard/[type]/programmes/[programId]/[round]/[applicationId]/application',
                            component: () => import('/app/src/pages/authenticated/jurors-dashboard/[type]/programmes/[programId]/[round]/[applicationId]/application.vue'),
                            props: true,
                            /* no children */
                            meta: {
                              "layout": "userDashboard",
                              "middlewares": [
                                "isJuror"
                              ]
                            }
                          },
                          {
                            path: 'evaluate',
                            name: '/jurors-dashboard/[type]/programmes/[programId]/[round]/[applicationId]/evaluate',
                            component: () => import('/app/src/pages/authenticated/jurors-dashboard/[type]/programmes/[programId]/[round]/[applicationId]/evaluate.vue'),
                            props: true,
                            /* no children */
                            meta: {
                              "layout": "userDashboard",
                              "middlewares": [
                                "isJuror"
                              ]
                            }
                          }
                        ],
                        meta: {
                          "middlewares": [
                            "isJuror"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isJuror"
                      ]
                    }
                  },
                  {
                    path: 'components',
                    /* internal name: '/jurors-dashboard/[type]/programmes/[programId]/components' */
                    /* no component */
                    children: [
                      {
                        path: 'filesList',
                        /* internal name: '/jurors-dashboard/[type]/programmes/[programId]/components/filesList' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '/jurors-dashboard/[type]/programmes/[programId]/components/filesList/',
                            component: () => import('/app/src/pages/authenticated/jurors-dashboard/[type]/programmes/[programId]/components/filesList/index.vue'),
                            /* no children */
                            meta: {
                              "layout": "userDashboard",
                              "middlewares": [
                                "isJuror"
                              ]
                            }
                          }
                        ],
                        meta: {
                          "middlewares": [
                            "isJuror"
                          ]
                        }
                      }
                    ],
                    meta: {
                      "middlewares": [
                        "isJuror"
                      ]
                    }
                  }
                ],
                meta: {
                  "middlewares": [
                    "isJuror"
                  ]
                }
              }
            ],
            meta: {
              "middlewares": [
                "isJuror"
              ]
            }
          }
        ],
        meta: {
          "middlewares": [
            "isJuror"
          ]
        }
      }
    ],
    meta: {
      "middlewares": [
        "isJuror"
      ]
    }
  },
  {
    path: '/legal-notice',
    name: '/legal-notice',
    component: () => import('/app/src/pages/shared/legal-notice.vue'),
    /* no children */
    meta: {
      "layout": "blank"
    }
  },
  {
    path: '/login',
    /* internal name: '/login' */
    /* no component */
    children: [
      {
        path: '',
        name: '/login/',
        component: () => import('/app/src/pages/shared/login/index.vue'),
        /* no children */
        meta: {
          "middlewares": [
            "isGuest"
          ]
        }
      }
    ],
  },
  {
    path: '/privacy-policy',
    /* internal name: '/privacy-policy' */
    /* no component */
    children: [
      {
        path: '',
        name: '/privacy-policy/',
        component: () => import('/app/src/pages/shared/privacy-policy/index.vue'),
        /* no children */
        meta: {
          "layout": "blank"
        }
      }
    ],
  },
  {
    path: '/register',
    /* internal name: '/register' */
    /* no component */
    children: [
      {
        path: '',
        name: '/register/',
        component: () => import('/app/src/pages/shared/register/index.vue'),
        /* no children */
        meta: {
          "middlewares": [
            "isGuest"
          ]
        }
      },
      {
        path: 'activate',
        /* internal name: '/register/activate' */
        /* no component */
        children: [
          {
            path: ':userid',
            /* internal name: '/register/activate/[userid]' */
            /* no component */
            children: [
              {
                path: ':token',
                name: '/register/activate/[userid]/[token]',
                component: () => import('/app/src/pages/shared/register/activate/[userid]/[token].vue'),
                props: true,
                /* no children */
                meta: {
                  "middlewares": [
                    "isGuest"
                  ]
                }
              }
            ],
          }
        ],
      },
      {
        path: 'complete',
        name: '/register/complete',
        component: () => import('/app/src/pages/shared/register/complete.vue'),
        /* no children */
        meta: {
          "middlewares": [
            "isAuthenticated"
          ]
        }
      }
    ],
  },
  {
    path: '/reset-password',
    /* internal name: '/reset-password' */
    /* no component */
    children: [
      {
        path: '',
        name: '/reset-password/',
        component: () => import('/app/src/pages/shared/reset-password/index.vue'),
        /* no children */
        meta: {
          "middlewares": [
            "isGuest"
          ]
        }
      },
      {
        path: ':userid',
        /* internal name: '/reset-password/[userid]' */
        /* no component */
        children: [
          {
            path: ':token',
            name: '/reset-password/[userid]/[token]',
            component: () => import('/app/src/pages/shared/reset-password/[userid]/[token].vue'),
            props: true,
            /* no children */
            meta: {
              "middlewares": [
                "isGuest"
              ]
            }
          }
        ],
      }
    ],
  },
  {
    path: '/settings',
    /* internal name: '/settings' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'User Settings',
        component: () => import('/app/src/pages/authenticated/settings/index.vue'),
        children: [
          {
            path: 'account',
            name: 'Account Settings',
            component: () => import('/app/src/pages/authenticated/settings/index/account.vue'),
            /* no children */
            meta: {
              "layout": "blank",
              "middlewares": [
                "isAuthenticated"
              ]
            }
          },
          {
            path: 'email',
            name: 'Email Settings',
            component: () => import('/app/src/pages/authenticated/settings/index/email.vue'),
            /* no children */
            meta: {
              "layout": "blank",
              "middlewares": [
                "isAuthenticated"
              ]
            }
          },
          {
            path: 'password',
            name: 'Password Settings',
            component: () => import('/app/src/pages/authenticated/settings/index/password.vue'),
            /* no children */
            meta: {
              "layout": "blank",
              "middlewares": [
                "isAuthenticated"
              ]
            }
          },
          {
            path: 'profile',
            name: 'Profile Settings',
            component: () => import('/app/src/pages/authenticated/settings/index/profile.vue'),
            /* no children */
            meta: {
              "layout": "blank",
              "middlewares": [
                "isAuthenticated"
              ]
            }
          }
        ],
        meta: {
          "middlewares": [
            "isAuthenticated"
          ]
        }
      },
  _definePage_default_11
  ),
      {
        path: ':all(.*)',
        name: '/settings/[...all]',
        component: () => import('/app/src/pages/authenticated/settings/[...all].vue'),
        /* no children */
        meta: {
          "layout": "blank",
          "middlewares": [
            "isAuthenticated"
          ]
        }
      },
      {
        path: 'confirm',
        /* internal name: '/settings/confirm' */
        /* no component */
        children: [
          {
            path: ':userid',
            /* internal name: '/settings/confirm/[userid]' */
            /* no component */
            children: [
              {
                path: ':token',
                name: '/settings/confirm/[userid]/[token]',
                component: () => import('/app/src/pages/authenticated/settings/confirm/[userid]/[token].vue'),
                props: true,
                /* no children */
                meta: {
                  "middlewares": [
                    "isAuthenticated",
                    "isAuthenticated"
                  ]
                }
              }
            ],
            meta: {
              "middlewares": [
                "isAuthenticated"
              ]
            }
          }
        ],
        meta: {
          "middlewares": [
            "isAuthenticated"
          ]
        }
      }
    ],
    meta: {
      "middlewares": [
        "isAuthenticated"
      ]
    }
  }
]
