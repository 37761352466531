<script setup lang="ts">
import { ref, watch } from 'vue'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

interface GlobalModalProps {
  show: boolean
  title?: string
  description?: string
  submitText?: string
  closeBtnVisible?: boolean
  loading?: boolean
}

const props = withDefaults(defineProps<GlobalModalProps>(), {
  show: false,
  title: '',
  description: '',
  submitText: 'OK',
  closeBtnVisible: false,
  loading: false,
})

const emit = defineEmits(['close'])

const closeButtonRef = ref(null)

const slots = useSlots()

const utilitiesStore = useUtilitiesStore()

const isOpen = ref(false)
const isLoading = ref(props.loading)

const closeModal = async (override = Object.keys(slots).length > 0, canceled = true, submit = false) => {
  if (override) {
    if (submit && utilitiesStore.globalModal?.submitEvent) {
      isLoading.value = true
      await utilitiesStore.globalModal?.submitEvent()
      isOpen.value = false
    }
    if (utilitiesStore.globalModal?.closeEvent) {
      await utilitiesStore.globalModal?.closeEvent(canceled)
      isOpen.value = false
    }
    else {
      isOpen.value = false
      emit('close', canceled)
    }

    // If we are using this as global modal, we need to reset it
    if (utilitiesStore.globalModal) {
      setTimeout(() => {
        utilitiesStore.globalModal = null
      }, 300)
    }
  }
}

defineExpose({
  closeModal,
})

watch(
  () => props.show,
  (show) => {
    isOpen.value = show
  },
  { immediate: true },
)
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog
      id="globalModal"
      as="div"
      class="relative z-50"
      :initial-focus="closeButtonRef"
      @close="closeModal()"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25"></div>
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="relative w-full max-w-md rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all"
            >
              <button
                v-if="Object.keys(slots).length > 0 || props.closeBtnVisible"
                ref="closeButtonRef"
                class="btn btn-circle absolute right-2 top-2 size-8 min-h-0 outline-none"
                @click="closeModal(true)"
              >
                ✕
              </button>

              <div v-if="Object.keys(slots).length > 0">
                <slot></slot>
              </div>

              <div v-else>
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  {{ props.title }}
                </DialogTitle>
                <div class="mt-2">
                  <p
                    class="text-sm text-gray-500"
                    v-html="props.description"
                  ></p>
                </div>
                <div class="mt-4">
                  <button
                    type="button"
                    :disabled="isLoading"
                    class="btn btn-primary btn-sm min-w-[100px]"
                    @click="closeModal(true, false, true)"
                  >
                    <span v-if="isLoading" class="loading loading-spinner size-4"></span>
                    {{ props.submitText }}
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
