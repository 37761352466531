<script lang="ts" setup>
definePage({
  redirect: '/admin/enterprise/awards/applications',
  alias: ['/admin/enterprise/awards/'],
  name: 'Awards',
  meta: {
    layout: 'userDashboard',
  },
})
</script>

<template>
  <div></div>
</template>
