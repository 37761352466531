<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'

const props = withDefaults(defineProps<{ numericOnly?: boolean, modelValue: string[], errors?: null | string[] }>(), {
  numericOnly: true,
  errors: null,
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()

const state = reactive({
  tags: [] as string[],
})

watch(() => props.modelValue, (newVal) => {
  // console.log(newVal)
  if (newVal)
    state.tags = newVal
  else
    state.tags = []
}, { immediate: true })

watch(() => state.tags, (newVal) => {
  emit('update:modelValue', newVal)
}, { deep: true })

const getUniqueValues = (existingValues: string[], newValues: string[]): string[] => {
  const uniqueSet = new Set([...existingValues, ...newValues])
  return Array.from(uniqueSet)
}

const addTag = (event: KeyboardEvent) => {
  const target = event.target as HTMLInputElement
  const val = target.value.trim()
  if (val.length > 0) {
    if (state.tags.length >= 1) {
      for (let i = 0; i < state.tags.length; i++) {
        if (state.tags[i] === val)
          return false
      }
    }
    state.tags.push(val)
    target.value = ''
  }
  event.preventDefault()
}
const removeTag = (index: number) => {
  state.tags.splice(index, 1)
}
const removeLastTag = (event: KeyboardEvent) => {
  if ((event.target as HTMLInputElement).value.length === 0)
    removeTag(state.tags.length - 1)
}

const onPaste = (event: ClipboardEvent) => {
  const pastedContent = event.clipboardData?.getData('text/plain')
  if (pastedContent) {
    const numericValues = pastedContent.replace(/[^\d,]/g, '')
    const values = numericValues.split(',').filter(value => value.trim() !== '')
    state.tags = getUniqueValues(state.tags, values)
  }
  event.preventDefault()
}

const onKeyDown = (event: KeyboardEvent) => {
  // Allow only numeric keys, backspace, delete, comma, enter, and paste shortcuts
  const allowedKeys = ['Backspace', 'Delete', 'Enter', 'Comma', 'Tab']

  // Allow paste shortcuts with Meta (Command key on macOS) or Control key
  if (
    !props.numericOnly || (
      (event.key === 'v' || event.key === 'V' || event.key === 'a' || event.key === 'A')
      && (event.metaKey || event.ctrlKey))
  )
    return

  if (
    !/\d/.test(event.key)
    && !allowedKeys.includes(event.key)
    && event.key !== 'Unidentified'
  )
    event.preventDefault()
}

const getErrorText = computed(() => props.errors ? Object.values(props.errors)[0][0] : '')
</script>

<template>
  <div class="select-solid select w-full cursor-auto bg-none" :class="{ 'ring-1 ring-error': getErrorText }">
    <div v-for="(tag, index) in state.tags" :key="tag" class="badge badge-neutral">
      {{ tag }}
      <XMarkIcon class="closeIcon size-3" @click="removeTag(index)" />
    </div>
    <input
      type="text"
      class="tag-input__text flex grow"
      inputmode="numeric"
      :placeholder="props.numericOnly ? t('inputTags.numericPlaceholder') : t('inputTags.textPlaceholder')"
      @keydown="onKeyDown"
      @keydown.enter="addTag"
      @keydown.,="addTag"
      @keydown.delete="removeLastTag"
      @paste="onPaste"
    />
  </div>
  <div class="text-left text-sm text-error">
    {{ getErrorText }}
  </div>
</template>

<style lang="scss" scoped>
.select-solid {
  @apply h-auto flex flex-wrap justify-start px-5 py-2.5;
  .closeIcon {
    cursor: pointer;
    display: inline-block;
    margin-left: 8px;
  }
}

.tag-input__text {
  outline: none;
  background: none;
  width: auto;
  height: 16px;
}
</style>
