import { createPinia } from 'pinia'

const pinia = createPinia()

function initUser() {
  return new Promise((resolve) => {
    const authStore = useAuthStore(pinia)
    authStore.fetchUser().then(() => {
      resolve(true)
    })
  })
}

export { pinia, initUser }
