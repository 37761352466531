import type { FormKitNode } from '@formkit/core'

export const wordCount = (node: FormKitNode, count = 0) => {
  if (count === 0)
    return true

  const words = typeof node.value === 'string'
    ? node.value.split(' ').filter(word => word !== '').length
    : 0

  return words <= count
}
