import Cookies from 'js-cookie'

// Check if cookies are allowed or declined, if yes return true
export const consentChecked = ref(!!Cookies.get('cookiesAccepted'))
// Check if additional consent is accepted
export const additionalConsentAccepted = ref(!!Cookies.get('mtm_consent'))
// Accept analytic cookies
export async function acceptConsent(all = true) {
  if (import.meta.env.MODE === 'production' && all) {
    window._paq.push(['rememberConsentGiven'])
    additionalConsentAccepted.value = true
  }

  Cookies.set('cookiesAccepted', String(true), { expires: 365, SameSite: 'Strict' })

  consentChecked.value = true
}
// Deny analytic cookies
export async function denyConsent() {
  if (import.meta.env.MODE === 'production')
    window._paq.push(['forgetConsentGiven'])

  Cookies.remove('matomo_sessid')
  Cookies.remove('_pk_ses.158.1fff')
  Cookies.remove('_pk_ref.158.1fff')
  Cookies.remove('_pk_id.158.1fff')
}
