<script lang="ts" setup>
import { UserIcon } from '@heroicons/vue/24/outline'

const { t } = useI18n()

const navigation = computed(() => [
  { name: t('home'), href: '/' },
  { name: t('programs'), href: '/#programmes' },
])
</script>

<template>
  <div
    class="mt-5 flex flex-col gap-x-0 gap-y-4 sm:mt-0 sm:flex-row sm:items-center sm:gap-x-7 sm:gap-y-0 sm:pl-7"
  >
    <router-link v-for="(navLink, i) in navigation" :key="i" class="navLink" :to="navLink.href">{{ navLink.name }}</router-link>

    <div class="flex items-center gap-x-2 sm:ml-auto">
      <router-link class="navLink flex items-center gap-x-2" to="/login">
        <UserIcon class="size-4" />
        {{ t('login') }}
      </router-link>
    </div>
    <LangSwitcher />
  </div>
</template>
