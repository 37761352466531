<script setup lang="ts">
import {
  AtSymbolIcon,
  UserCircleIcon,
} from '@heroicons/vue/24/outline'
import PasswordIcon from '~icons/gg/password'

useSeoMeta({
  title: 'User Settings',
})

definePage({
  name: 'User Settings',
  redirect: '/settings/profile',
  alias: ['/settings/'],
  meta: {
    layout: 'userDashboard',
  },
})

const navigation = [
  { name: 'Profile settings', href: '/settings/profile', icon: UserCircleIcon },
  { name: 'Email settings', href: '/settings/email', icon: AtSymbolIcon },
  { name: 'Password settings', href: '/settings/password', icon: PasswordIcon },
]
</script>

<template>
  <div>
    <div class="m-0 flex flex-col items-center justify-center">
      <SubHeading
        title="User settings"
      />
    </div>
    <div class="mx-auto mt-6 flex w-full max-w-7xl flex-col justify-between space-y-8 md:flex-row md:space-x-3 md:space-y-0">
      <div class="card flex w-full rounded-xl border border-gray-200 bg-white shadow-sm md:w-[300px]">
        <div class="card-body flex grow flex-col gap-y-5 overflow-y-auto px-4">
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="space-y-1">
                  <li v-for="item in navigation" :key="item.name" class="divide-dashed">
                    <router-link :to="item.href" class="group flex items-center gap-x-1.5 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50">
                      <component :is="item.icon" class="size-5 shrink-0" aria-hidden="true" />
                      {{ item.name }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="card flex w-full rounded-xl border border-gray-200 bg-white shadow-sm">
        <div class="rounded-t-xl border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <router-view v-slot="{ route }">
            <h3 class="text-base font-semibold leading-6 text-gray-900">{{ route.name }}</h3>
          </router-view>
        </div>
        <main class="card-body pb-10 pt-6">
          <router-view v-slot="{ Component, route }">
            <component :is="Component" v-if="Component" :key="route.fullPath" />
          </router-view>
        </main>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.router-link-active {
  @apply bg-gray-100 text-primary;
}
</style>
