<script setup lang="ts">
import NavLogo from './_navLogo.vue'
import GuestNav from './_guestNav.vue'
import EnterpriseUserNav from './_enterpriseUserNav.vue'
import JurorNav from './_jurorNav.vue'
import BdsUserNav from './_bdsUserNav.vue'
import AdminNav from './_adminNav.vue'
import IncompleteUserNav from './_incompleteUserNav.vue'

const authStore = useAuthStore()
const { isEnterpriseUser, isBdsUser, isJuror, isAdmin, isGuest, isIncompleteUser } = storeToRefs(authStore)
</script>

<template>
  <header
    id="headerNav"
    class="z-50 flex h-[64px] w-full flex-wrap bg-secondary px-4 py-3 text-sm sm:flex-nowrap sm:justify-start"
  >
    <nav
      class="relative mx-auto w-full sm:flex sm:items-center sm:justify-between"
      :class="{ 'max-w-7xl': $route.meta.layout !== 'admin' }"
      aria-label="Global"
    >
      <div class="flex w-[120px] items-center justify-between">
        <NavLogo />
      </div>
      <div
        class="hs-collapse hidden grow basis-full transition-all duration-300 sm:block"
      >
        <AdminNav v-if="isAdmin" data-testid="admin-nav" />

        <JurorNav v-else-if="isJuror" data-testid="enterprise-juror-nav" />

        <!-- Enterprise -->
        <EnterpriseUserNav v-if="isEnterpriseUser" data-testid="enterprise-nav" />
        <!-- BDS+ -->
        <BdsUserNav v-else-if="isBdsUser" data-testid="bds-nav" />

        <GuestNav v-else-if="isGuest" data-testid="guest-nav" />

        <IncompleteUserNav v-else-if="isIncompleteUser" data-testid="incomplete-nav" />
      </div>
    </nav>
  </header>
</template>

<style lang="scss">
#headerNav .navLink {
  @apply text-white/[.8] hover:text-white font-medium duration-200;
  * {
    @apply text-white/[.8] hover:text-white font-medium
  }
  &.router-link-active {
    @apply text-white;
  }
}
</style>
