import axios from 'axios'
import { defineStore } from 'pinia'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'
import type {
  LoginInput,
  LoginResponse,
  RefreshTokenResponse,
  User,
} from '@/types/authTypes'
import { handleApiError } from '@/router/errorHandler'
import router from '@/router/router'

export const useAuthStore = defineStore('AuthStore', {
  state: (): {
    accessToken: string | null
    refreshToken: string | null
    user: User | null
  } => ({
    accessToken: Cookies.get('access') || null,
    refreshToken: Cookies.get('refresh') || null,
    user: null,
  }),
  getters: {
    userName: (state) => {
      if (state.user) {
        if (import.meta.env.MODE === 'development')
          return `${state.user.first_name ?? 'User'} ${state.user.id}`

        return `${state.user.first_name} ${state.user.last_name}`
      }
      return null
    },
    isGuest: (state) => {
      return !state.accessToken && !state.user
    },
    isIncompleteUser: (state) => {
      if (state.user)
        return !state.user.role && !state.user.is_staff
      return false
    },
    isEnterpriseUser: (state) => {
      if (state.user)
        return state.user.role && !state.user.is_staff && state.user.role === 'ENTREPRENEUR' && !state.user.is_juror
      return false
    },
    isBdsUser: (state) => {
      if (state.user)
        return state.user.role && !state.user.is_staff && state.user.role === 'BDS_PROVIDER' && !state.user.is_juror
      return false
    },
    entityApiPath() {
      if (this.isBdsUser)
        return 'organisations'
      return 'enterprises'
    },
    isJuror: (state) => {
      if (state.user)
        return state.user.is_juror && state.user.role
      return false
    },
    isAdmin: (state) => {
      if (state.user)
        return state.user.is_staff
      return false
    },
    redirectUserPath() {
      if (this.user) {
        if (this.isAdmin)
          return '/admin'

        if (this.isJuror)
          return '/jurors-dashboard'

        if (this.isEnterpriseUser)
          return '/dashboard'

        if (this.isBdsUser)
          return '/dashboard'

        if (this.isIncompleteUser)
          return '/register/complete'
      }
      return '/'
    },
  },
  actions: {
    setUser(userData: User) {
      this.user = userData
      Sentry.setUser({ id: userData.id })
    },
    async login(credentials: LoginInput) {
      const utilitiesStore = useUtilitiesStore()

      try {
        const response = await axios.post<LoginResponse>(
          '/auth_token/',
          credentials,
        )
        this.accessToken = response.data.access
        this.refreshToken = response.data.refresh
        this.setUser(response.data.user)

        Cookies.set('access', this.accessToken)
        Cookies.set('refresh', this.refreshToken)
      }
      catch (error: any) {
        console.error(error)
        utilitiesStore.alertMessage = {
          text: handleApiError(error),
          error: true,
        }
      }
    },
    async initRefreshToken() {
      const utilitiesStore = useUtilitiesStore()

      try {
        const response = await axios.post<RefreshTokenResponse>(
          '/auth_token/refresh/',
          {
            refresh: this.refreshToken,
          },
        )
        this.accessToken = response.data.access
        Cookies.set('access', this.accessToken)
      }
      catch (error: any) {
        console.error(error)
        utilitiesStore.alertMessage = {
          text: handleApiError(error),
          error: true,
        }
        if (error?.response?.status === 401)
          await this.redirectUser()
      }
    },
    async fetchUser() {
      if (this.accessToken === null || this.refreshToken === null)
        return
      const utilitiesStore = useUtilitiesStore()

      try {
        const response = await axios.get<User>('/me/')
        this.setUser(response.data)
      }
      catch (error: any) {
        utilitiesStore.alertMessage = {
          text: handleApiError(error),
          error: true,
        }
        await this.logout()
      }
    },
    async logout() {
      await router.push('/')
      this.accessToken = null
      this.refreshToken = null
      this.user = null
      Cookies.remove('access')
      Cookies.remove('refresh')
      Sentry.setUser(null)
    },
    async redirectUser() {
      await router.push(this.redirectUserPath)
    },
  },
})
