const textClassification = {
  input: 'input select-solid !h-[42px] bg-none formkit-invalid:input-error w-full',
}
const selectClassification = {
  input: 'select-solid select w-full formkit-invalid:select-error',
}
const boxClassification = {
  // fieldset: "max-w-md border border-gray-400 rounded-md px-2 pb-1",
  legend: 'font-bold text-sm mb-2',
  wrapper: 'flex items-center mb-4',
  help: 'mb-2',
  input:
    'checkbox checkbox-primary border-gray-300 transition duration-150 ease-in-out',
  inner: 'flex items-center mr-2',
  label: 'text-md font-normal text-gray-700 cursor-pointer',
}

const radioClassification = {
  // fieldset: "max-w-md border border-gray-400 rounded-md px-2 pb-1",
  legend: 'font-bold text-sm mb-2',
  wrapper: 'flex items-center mb-4',
  help: 'mb-2',
  input:
    'radio radio-primary border-gray-300 transition duration-150 ease-in-out',
  inner: 'flex items-center mr-2',
  label: 'text-md font-normal text-gray-700 cursor-pointer',
}
const buttonClassification = {
  wrapper: 'mb-1',
  input: 'btn btn-primary btn-wide btn-outline w-auto',
}

const textAreaClassification = { ...textClassification, input: 'input select-solid h-auto w-full', inner: 'mt-1' }
textAreaClassification.input = `${textAreaClassification.input} h-32 textarea`

// export our definitions using our above
// templates and declare one-offs and
// overrides as needed.
export default {
  // the global key will apply to all inputs
  'global': {
    form: 'space-y-6',
    outer: 'formkit-disabled:opacity-50 mb-4',
    messages: 'w-full list-none p-0 mt-1 mb-0',
    message: 'text-error mb-1 text-xs font-semibold text-left',
    label: 'font-bold text-sm formkit-invalid:text-error',
    help: 'text-xs text-gray-500',
  },
  'button': buttonClassification,
  'color': {
    label: 'block mb-1 font-bold text-sm',
    input:
      'w-16 h-8 appearance-none cursor-pointer border border-gray-300 rounded-md mb-2 p-1',
  },
  'date': textClassification,
  'datetime-local': textClassification,
  'checkbox': boxClassification,
  'email': textClassification,
  'file': {
    wrapper: 'text-left border rounded-md px-3 py-2',
    fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
    fileItemIcon: 'w-4 mr-2 shrink-0',
    fileList: 'shrink grow peer pt-2',
    fileName: 'break-all grow text-ellipsis',
    fileRemove: 'btn btn-link relative z-[2] text-[0px] px-0 peer-data-[has-multiple=true]:text-sm',
    fileRemoveIcon: 'block text-base w-4 relative z-[2]',
    inner: 'relative max-w-md cursor-pointer',
    input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
    noFiles: 'flex w-full items-center px-0 py-2 text-gray-400',
    noFilesIcon: 'w-4 mr-2',
    help: 'text-left px-3 py-2 text-gray-400',
  },
  'month': textClassification,
  'number': textClassification,
  'password': textClassification,
  'radio': radioClassification,
  'range': {
    inner: 'max-w-md',
    input:
      'form-range appearance-none w-full h-2 p-0 bg-gray-200 rounded-full focus:outline-none focus:ring-0 focus:shadow-none',
  },
  'search': textClassification,
  'select': selectClassification,
  'submit': buttonClassification,
  'tel': textClassification,
  'text': textClassification,
  'textarea': textAreaClassification,
  'time': textClassification,
  'url': textClassification,
  'week': textClassification,
}
