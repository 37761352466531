<script lang="ts" setup>
const { locale } = useI18n()

definePage({
  meta: {
    layout: 'userDashboard',
  },
})

useSeoMeta({
  title: 'Measurable Targets',
})
</script>

<template>
  <div
    class="size-full space-y-4 text-left"
  >
    <div v-if="locale === 'fr'">
      <p><strong class="text-xl">Objectifs Mesurables et Triple Bilan</strong></p>
      <p><br /></p>
      <p><strong>Que sont les objectifs SMART ?&nbsp;</strong></p>
      <p>Les<strong>&nbsp;objectifs SMART&nbsp;</strong>sont un acronyme construit autour des caract&eacute;ristiques cl&eacute;s des objectifs significatifs, qui peuvent s&apos;av&eacute;rer tr&egrave;s utiles pour &eacute;valuer la qualit&eacute; des projets propos&eacute;s et r&eacute;alis&eacute;s.&nbsp;</p>
      <p><br /></p>
      <ul>
        <li><strong>S</strong>p&eacute;cifique - les objectifs doivent porter sur une seule question et exprimer clairement ce qu&apos;ils veulent atteindre (concrets, d&eacute;taill&eacute;s, bien d&eacute;finis).&nbsp;</li>
        <li><strong>M</strong>esurable - il est n&eacute;cessaire de pouvoir mesurer si un objectif est atteint ou non (chiffres, quantit&eacute;, comparaison). &nbsp;</li>
        <li><strong>A</strong>tteignable - tous les objectifs doivent &ecirc;tre r&eacute;alisables et convenus entre les partenaires. &nbsp;</li>
        <li><strong>R</strong>&eacute;alisable - il doit &ecirc;tre possible d&apos;atteindre chaque objectif avec les ressources disponibles.&nbsp;</li>
        <li><strong>T</strong>emporel - la cl&eacute; de la d&eacute;finition des objectifs est l&apos;estimation du d&eacute;lai dans lequel chaque objectif aura &eacute;t&eacute; atteint, en laissant suffisamment de temps pour atteindre l&apos;objectif, mais pas trop longtemps pour ne pas r&eacute;duire les performances. &nbsp;</li>
      </ul>
      <p><br /></p>
      <p><strong>Quelle est la signification du Triple Bilan ?&nbsp;</strong></p>
      <p>Le <strong>Triple Bilan</strong> consiste &agrave; int&eacute;grer l&apos;impact social et environnemental des activit&eacute;s d&apos;une organisation &agrave; ses performances &eacute;conomiques. Les &eacute;l&eacute;ments du triple bilan sont les suivants : les personnes, les profits et la plan&egrave;te. Les entreprises qui peuvent apporter une contribution significative au d&eacute;veloppement durable en fournissant : &nbsp;</p>
      <ul>
        <li><strong>Des avantages sociaux&nbsp;</strong>tels que l&apos;organisation de la communaut&eacute;, l&apos;autonomisation et l&apos;emploi, des avantages en mati&egrave;re de sant&eacute; ou d&apos;&eacute;ducation, ou l&apos;acc&egrave;s &agrave; des services essentiels&nbsp;; et&nbsp;</li>
        <li><strong>Des avantages environnementaux&nbsp;</strong>tels que la r&eacute;duction des &eacute;missions de carbone, des d&eacute;chets ou de la pollution, ou la protection de la biodiversit&eacute; et des &eacute;cosyst&egrave;mes&nbsp;; et</li>
        <li><strong>Des avantages &eacute;conomiques</strong>, notamment la cr&eacute;ation de revenus pour les partenaires principaux, la communaut&eacute; et les acteurs tout au long de la cha&icirc;ne de valeur.</li>
      </ul>
    </div>
    <div v-else>
      <p><strong class="text-xl">Measurable Targets &amp; Triple Bottom Line</strong></p>
      <p><br /></p>
      <p><strong>What are SMART targets?&nbsp;</strong></p>
      <p><strong>SMART targets&nbsp;</strong>refer to an acronym built around the key characteristics of meaningful targets, which can be very helpful in evaluating the quality of projects proposed and carried out.&nbsp;</p>
      <p><br /></p>
      <ul>
        <li><strong>S</strong>pecific<strong>&nbsp;</strong>&ndash; targets should be single issue and express clearly what they want to achieve (concrete, detailed, well defined)&nbsp;</li>
        <li><strong>M</strong>easurable &ndash; it is necessary to be able to measure whether a target is being met, or not (numbers, quantity, comparison)&nbsp;</li>
        <li><strong>A</strong>chievable &ndash; all targets should be achievable and attainable, and agreed upon between partners&nbsp;</li>
        <li><strong>R</strong>ealistic &ndash; it should be possible to reach each target with the resources available.&nbsp;</li>
        <li><strong>T</strong>ime &ndash; key to target setting is estimating the time by which each target will have been reached, allowing sufficient time to reach the goal, but not so long as to reduce performance.&nbsp;</li>
      </ul>
      <p><br /></p>
      <p><strong>What is the meaning of Triple Bottom Line?&nbsp;</strong></p>
      <p>The <strong>triple bottom line&nbsp;</strong>relates to integration of the social and environmental impact of an organisation&apos;s activities with its economic performance. The elements of the triple bottom line are referred to as people, profits and planet. Enterprises that can make a significant contribution to sustainable development by delivering:&nbsp;</p>
      <ul>
        <li><strong>Social</strong> benefits such as community organisation, empowerment and employment, health or education benefits, or access to essential services; and&nbsp;</li>
        <li><strong>Environmental</strong> benefits such as reduced carbon emissions, waste or pollution, or protecting biodiversity and ecosystems; and&nbsp;</li>
        <li><strong>Economic</strong> benefits including income generation for the leading partners, community and actors along the value chain.</li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
p.p1 {
  margin: 0.0px 0.0px 6.0px 0.0px;
  text-align: justify;
  font: 18.0px Arial;
  color: #000000
}

p.p2 {
  margin: 0.0px 0.0px 6.0px 0.0px;
  text-align: justify;
  line-height: 12.0px;
  font: 18.0px Arial;
  min-height: 21.0px
}

p.p3 {
  margin: 0.0px 0.0px 10.0px 0.0px;
  text-align: justify;
  font: 14.0px Arial;
  color: #000000
}

p.p4 {
  margin: 0.0px 0.0px 6.0px 0.0px;
  text-align: justify;
  font: 11.0px Arial;
  color: #000000
}

p.p5 {
  margin: 0.0px 0.0px 6.0px 0.0px;
  text-align: justify;
  font: 11.0px Arial;
  min-height: 12.0px
}

p.p7 {
  margin: 0.0px 0.0px 6.0px 0.0px;
  text-align: justify;
  font: 14.0px Arial;
  color: #000000
}

p.p8 {
  margin: 0.0px 0.0px 6.0px 0.0px;
  text-align: justify;
  line-height: 12.0px;
  font: 11.0px Arial;
  color: #000000
}

li.li6 {
  margin: 0.0px 0.0px 10.0px 0.0px;
  text-align: justify;
  font: 11.0px Arial;
  color: #000000
}

span.s1 {
  text-decoration: underline
}

span.s2 {
  font: 11.0px Symbol
}

ul.ul1 {
  list-style-type: disc
}
</style>
