<script setup lang="ts">
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import type { FormKitFrameworkContext } from '@formkit/core'
import type { Ref } from 'vue'
import { refDebounced, useDebounceFn } from '@vueuse/core'

const props = defineProps<{
  context: FormKitFrameworkContext
}>()

const { t } = useI18n()

interface Item {
  [key: string]: any
}

interface PropsType {
  'items': Item[]
  'fields': (string | string[])[]
  'multiple'?: boolean
  'loading': boolean
  'extract-column'?: string
  'get-items'?: (search: string) => void
}

const comboBoxSearchInputRef = ref<InstanceType<typeof ComboboxInput>>()
const comboBoxOptionsRef = ref<InstanceType<typeof ComboboxOptions>>()

type SelectedItemType = Item | Item[] | null

const propItems = ref(props.context.attrs) as Ref<PropsType>
const isMultiple = ref(propItems.value.multiple)
const extractColumn = ref(propItems.value['extract-column'] || '')
const getItemsFn = ref(propItems.value['get-items'] || null)

const selectedItems = ref<SelectedItemType>(isMultiple.value ? [] : null)
const searchQuery = ref('')
const debouncedSearchQuery = refDebounced(searchQuery, getItemsFn.value ? 300 : 0)

const value = computed(() => {
  return props.context?._value
})

const selectedItemsCache = ref<SelectedItemType>(isMultiple.value ? [] : null)

const itemsMap = computed(() => new Map(propItems.value.items.map(item => [item[extractColumn.value], item])))

const mergeWithoutDuplicates = (existingItems: Item[], newItems: Item[], uniqueProp = 'id') => {
  const mergedItems = [...existingItems]

  newItems.forEach((newItem) => {
    if (typeof newItem === 'object' && newItem !== null && uniqueProp) {
      // For objects, check uniqueness based on the provided unique property.
      if (!mergedItems.some(item => item[uniqueProp] === newItem[uniqueProp]))
        mergedItems.push(newItem)
    }
    else {
      // For primitive types, just check if the item is not already included.
      if (!mergedItems.includes(newItem))
        mergedItems.push(newItem)
    }
  })

  return mergedItems
}

const loadInitialValues = (reset = false) => {
  const initValue = value.value
  if (initValue !== null && (selectedItems.value === null || selectedItems.value.length === 0 || reset)) {
    if (Array.isArray(initValue)) {
      const prevSelectedItems = selectedItems.value as Item[]
      // Check if the first element of the array is an object (assuming homogeneous array)
      if (initValue.length > 0 && typeof initValue[0] === 'object') {
        // Assuming the array contains full objects
        selectedItems.value = mergeWithoutDuplicates(prevSelectedItems, initValue, extractColumn.value)
      }
      else if (initValue.length > 0) {
        // Assuming the array contains IDs
        const filteredInitValue = initValue.map(id => itemsMap.value.get(id)).filter(Boolean) as Item[]
        selectedItems.value = mergeWithoutDuplicates(prevSelectedItems, filteredInitValue, extractColumn.value)
      }
    }
    else if (typeof initValue === 'string' || typeof initValue === 'number') {
      // Handling a single ID value
      const item = itemsMap.value.get(initValue)
      selectedItems.value = item ? (isMultiple.value ? [item] : item) : null
    }
    else if (typeof initValue === 'object') {
      // Handling a single object value
      selectedItems.value = isMultiple.value ? [initValue] : initValue
    }
    if (!reset)
      selectedItemsCache.value = selectedItems.value
  }
}

watch (() => propItems.value.items, () => {
  loadInitialValues(true)
}, { deep: false, immediate: false })
watch (() => props.context.attrs, (newVal) => {
  propItems.value = newVal as PropsType
  loadInitialValues()
}, { deep: false, immediate: true })
watch (() => propItems.value.multiple, (newVal) => {
  isMultiple.value = newVal
}, { immediate: true })
watch (() => propItems.value['extract-column'], (newVal) => {
  extractColumn.value = newVal || ''
}, { immediate: true })

const firstField = (item: Item) => {
  if (typeof propItems.value.fields[0] === 'string') {
    return item[propItems.value.fields[0]]
  }
  else if (Array.isArray(propItems.value.fields[0])) {
    const subValues = propItems.value.fields[0].map(field => item[field])
    return subValues.join(' ')
  }
}

const allFields = (item: Item) => {
  return propItems.value.fields.flatMap((field) => {
    if (typeof field === 'string')
      return item[field]
    else
      return field.map(subField => item[subField]).join(' ')
  }).join(' ')
}

const filteredItems = computed(() => {
  const cleanQuery = debouncedSearchQuery.value.toLowerCase().replace(/\s+/g, '')

  return cleanQuery === ''
    ? propItems.value.items
    : propItems.value.items.filter(item => allFields(item).toLowerCase().replace(/\s+/g, '').includes(cleanQuery))
})

const emptyOption = { name: 'No results', disabled: true, empty: true }

const virtual = computed(() => ({
  options: filteredItems.value.length ? filteredItems.value : [emptyOption],
}))

const clearValues = () => {
  selectedItems.value = isMultiple.value ? [] : null
  selectedItemsCache.value = isMultiple.value ? [] : null
  searchQuery.value = ''
}

const updateSelectedItems = (item: SelectedItemType) => {
  if (item === null) {
    props.context?.node.input(isMultiple.value ? [] : null)
  }
  else {
    if (isMultiple.value) {
      extractColumn.value.length > 0 ? props.context?.node.input(item.map((e: Item) => e[extractColumn.value])) : props.context?.node.input(item)
      selectedItemsCache.value = item
    }
    else {
      extractColumn.value.length > 0 ? props.context?.node.input((item as Item)[extractColumn.value as string]) : props.context?.node.input(item)
      selectedItemsCache.value = item
    }
    if (!isMultiple.value && comboBoxSearchInputRef?.value?.$el && comboBoxSearchInputRef?.value?.$el?.value.length > 0) {
      comboBoxSearchInputRef.value.$el.value = firstField(item as Item)
      searchQuery.value = firstField(item as Item)
    }
  }
}

const deleteItem = (item: Item) => {
  if (selectedItems.value === null)
    return
  const index = selectedItems.value.indexOf(item)
  if (index > -1)
    selectedItems.value.splice(index, 1)
}

const displayValue = (item: Item | Item[]) => {
  if (!item)
    return null

  return isMultiple.value
    ? (item as Item[]).map(item => firstField(item)).join(', ')
    : firstField(item)
}

watch(() => selectedItemsCache.value, (newVal) => {
  updateSelectedItems(newVal)
}, { deep: true })

watch(() => searchQuery.value, useDebounceFn((newVal) => {
  if (getItemsFn.value)
    getItemsFn.value(newVal)
}, 200))

watch(() => value.value, () => {
  loadInitialValues(false)
}, { immediate: true })

const handleInputFocus = (e: Event) => {
  if (!comboBoxOptionsRef.value?.$el) {
    searchQuery.value = ''
    if (getItemsFn.value)
      getItemsFn.value('')
  }

  const target = e.target as HTMLElement
  const button = target?.nextSibling as HTMLButtonElement
  button?.click()
}
</script>

<template>
  <Combobox v-model="selectedItemsCache" as="template" :multiple="isMultiple" :nullable="true" :virtual="virtual" :by="extractColumn">
    <div class="relative">
      <div :class="[isMultiple ? 'tagsInput select-solid select w-full cursor-auto bg-none pr-14' : '']">
        <div v-if="isMultiple && selectedItemsCache?.length > 0" class="flex max-w-[90%] flex-wrap gap-2">
          <div v-for="(item, index) in selectedItemsCache as Item[]" :key="index" class="badge badge-neutral">
            {{ firstField(item) }}
            <XMarkIcon class="closeIcon size-3" @click="deleteItem(item)" />
          </div>
        </div>
        <ComboboxInput
          v-if="isMultiple"
          id="comboBoxSearchInput"
          autocomplete="off"
          :placeholder="t('comboBox.searchPlaceholder')"
          class="tag-input__text flex w-full grow pr-14"
          :class="{ 'formkit-invalid:input-error': context.state.validationVisible && !context.state.valid }"
          @change="searchQuery = $event.target.value"
          @click="handleInputFocus"
        />
        <ComboboxInput
          v-else
          id="comboBoxSearchInput"
          ref="comboBoxSearchInputRef"
          autocomplete="off"
          :placeholder="t('comboBox.searchPlaceholder')"
          class="select-solid select w-full cursor-auto bg-none pr-16 font-normal"
          :class="{ 'formkit-invalid:input-error': context.state.validationVisible && !context.state.valid }"
          :display-value="(item: any) => displayValue(item)"
          @change="searchQuery = $event.target.value"
          @click="handleInputFocus"
        />
        <ComboboxButton
          type="button"
          class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
        >
          <span v-if="propItems.loading" class="loading loading-spinner text-primary"></span>
          <ChevronUpDownIcon v-else class="size-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>
        <button
          v-if="(selectedItemsCache?.length > 0 && isMultiple && !propItems.loading)"
          type="button"
          class="absolute inset-y-0 right-8 flex items-center rounded-r-md px-2 focus:outline-none"
          @click.stop.prevent="clearValues()"
        >
          <XMarkIcon class="size-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>

      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <ComboboxOptions
          v-if="!propItems.loading"
          v-slot="{ option }"
          ref="comboBoxOptionsRef"
          :class="{ 'border-1 border-gray-200': filteredItems.length > 0 }"
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg focus:outline-none sm:text-sm"
        >
          <template v-if="option.empty && searchQuery !== '' && filteredItems.length === 0">
            <ComboboxOption
              :value="option"
              class="w-full"
            >
              <div class="relative cursor-default select-none px-4 py-2 text-gray-700">
                {{ t('noItemsFound') }}.
              </div>
            </ComboboxOption>
          </template>
          <template v-else-if="!propItems.loading && filteredItems.length > 0">
            <ComboboxOption
              v-slot="{ active, selected }"
              :value="option"
              class="w-full"
            >
              <li
                class="relative cursor-pointer select-none py-2 pl-8 pr-4 text-left"
                :class="{ 'bg-primary text-white': active, 'text-gray-900': !active }"
              >
                <div class="flex">
                  <span class="truncate" :class="[selected && 'font-semibold']">
                    {{ firstField(option) }}
                  </span>
                  <span
                    v-if="propItems.fields.length > 1" class="ml-2 truncate text-gray-500"
                    :class="[active ? 'text-white' : 'text-gray-500']"
                  >
                    {{ option[(propItems.fields as string[])[1]] }}
                  </span>
                </div>

                <span
                  v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-1.5"
                  :class="{ 'text-white': active, 'text-primary': !active }"
                >
                  <CheckIcon class="size-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </template>
        </ComboboxOptions>
      </transition>
    </div>
  </Combobox>
</template>

<style lang="scss" scoped>
.select-solid.tagsInput {
  @apply h-auto flex flex-wrap justify-start pl-4 pr-5;
  .closeIcon {
    cursor: pointer;
    display: inline-block;
    margin-left: 8px;
  }
}

.tag-input__text {
  outline: none;
  background: none;
  width: auto;
  height: 38px;
}
</style>
