import type { FormKitNode } from '@formkit/core'
import { clone } from '@formkit/utils'

export function createLoadingSpinnerPlugin() {
  return function loadingSpinnerPlugin(node: FormKitNode) {
    // bail if we're not a button
    if (node.props.family !== 'button')
      return

    node.addProps(['loading'])

    node.on('created', () => {
      const inputDefinition = clone<any>(node.props.definition)
      const originalSchema = inputDefinition.schema

      const higherOrderSchema = (extensions: any) => {
        extensions.input = {
          $el: 'button',
          attrs: {
            disabled: '$loading',
          },
          children: {
            if: '$loading',
            then: {
              $el: 'span',
              attrs: {
                class: 'loading',
              },
            },
            else: {
              if: '$label',
              then: '$label',
              else: '$slots.default',
            },
          },
        }

        return originalSchema(extensions)
      }
      inputDefinition.schema = higherOrderSchema
      node.props.definition = inputDefinition
    })
  }
}
