import axios from 'axios'
import NProgress from 'nprogress'
import { useLanguageStore } from '@/store/languageStore'

const localDomains = ['localhost', '127.0.0.1']
const hostname = window.location.hostname

const envBackEndUrl = import.meta.env.VITE_APP_BACKEND_URL
const isTestEnv = import.meta.env.MODE === 'test'

export const apiURL = envBackEndUrl ?? (hostname.startsWith('staging')
  ? 'https://staging.ep.adelphi.app/'
  : localDomains.includes(hostname)
    ? `http://${hostname}:8000/`
    : 'https://ep.adelphi.app/')

axios.defaults.baseURL = apiURL

axios.interceptors.request.use(
  (config) => {
    if (!isTestEnv)
      NProgress.start()

    const authStore = useAuthStore()
    const token = authStore.accessToken

    // Set appropriate authentication token if it exists
    if (token)
      config.headers.Authorization = `Bearer ${token}`

    const langStore = useLanguageStore()
    const lang = langStore.currentLanguage

    if (lang)
      config.headers['Accept-Language'] = lang // Set language for axios requests

    return config
  },
  (error) => {
    if (!isTestEnv)
      NProgress.done()

    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  (response) => {
    if (!isTestEnv)
      NProgress.done()

    return response
  },
  async (error: any) => {
    const originalRequest = error.config
    const authStore = useAuthStore()

    if (
      error?.response?.status === 401
      && !originalRequest.url.includes('/auth_token/refresh/')
      && !originalRequest.url.includes('/auth_token/')
    ) {
      // If the retry flag exists return the error without retrying
      if (originalRequest._retry) {
        if (!isTestEnv)
          NProgress.done()

        return Promise.reject(error)
      }

      // Set the retry flag to true to avoid another retry
      originalRequest._retry = true

      await authStore.initRefreshToken()

      const newRequest = {
        ...originalRequest,
        headers: {
          ...originalRequest.headers,
          Authorization: `Bearer ${authStore.accessToken}`,
        },
      }

      if (!isTestEnv)
        NProgress.done()

      return axios(newRequest)
    }

    if (!isTestEnv)
      NProgress.done()

    return Promise.reject(error)
  },
)
