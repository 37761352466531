<script setup>
import { useHead } from '@unhead/vue'
import CookiesConsent from '@/components/CookiesConsent.vue'
import NavBar from '@/components/NavBar/index.vue'
import FooterSection from '@/components/Footer.vue'
import GlobalAlert from '@/components/GlobalAlert.vue'
import GlobalModal from '@/components/GlobalModal.vue'
import { consentChecked } from '@/pages/shared/privacy-policy/checkCookies'

useHead({
  templateParams: {
    site: {
      name: 'SEED platform',
    },
    separator: '-',
  },
  titleTemplate: '%s %separator %site.name',
})

const utilitiesStore = useUtilitiesStore()
</script>

<template>
  <GlobalAlert />
  <Transition name="slide-alert">
    <CookiesConsent v-if="!consentChecked" />
  </Transition>
  <GlobalModal
    v-if="utilitiesStore.globalModal"
    :title="utilitiesStore.globalModal.title"
    :description="utilitiesStore.globalModal.description"
    :show="utilitiesStore.globalModal.show"
    :submit-text="utilitiesStore.globalModal.submitText"
    :close-btn-visible="utilitiesStore.globalModal.closeBtnVisible"
    :loading="utilitiesStore.globalModal.loading"
    @close="utilitiesStore.globalModal.closeEvent"
  />
  <div class="flex h-screen flex-col justify-between">
    <NavBar v-if="$route.meta.layout !== 'admin'" />
    <router-view v-slot="{ Component }">
      <Transition name="fade" mode="out-in">
        <component :is="Component" />
      </Transition>
    </router-view>
    <FooterSection />
  </div>
</template>
