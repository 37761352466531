<script setup lang="ts">
import type { FormKitConfig } from '@formkit/core'
import { useLanguageStore } from '@/store/languageStore'

const { locale } = useI18n()
const languageStore = useLanguageStore()
const config = inject(Symbol.for('FormKitConfig')) as FormKitConfig

const changeLanguage = (lang: string) => {
  config.locale = lang // Override FormKit locale config
  languageStore.setLanguage(lang)
  locale.value = lang
}

const availableLangs = ['en', 'fr']
</script>

<template>
  <div class=" h-9 w-[1px] bg-white"></div>
  <div role="tablist" class="tabs tabs-bordered">
    <button
      v-for="lang in availableLangs" :key="lang" role="tab" class="tab mr-2 px-1.5 uppercase focus:outline-0"
      :class="{ 'tab-active': locale === lang }" @click="changeLanguage(lang)"
    >
      {{ lang }}
    </button>
  </div>
</template>

<style lang="scss" scoped>
.tab {
  @apply text-white/[.8] hover:text-white font-medium duration-200 text-sm h-6;
  &:hover {
    @apply text-white;
  }
}

.tab-active {
  @apply text-white;
  border-color: #fff !important;
}
</style>
