<script lang="ts" setup>
import UserProfileDropdown from './_userProfileDropdown.vue'

const { t } = useI18n()

const navigation = computed(() => [
  { name: t('home'), href: '/jurors-dashboard' },
])
</script>

<template>
  <div
    class="mt-5 flex flex-col gap-x-0 gap-y-4 sm:mt-0 sm:flex-row sm:items-center sm:gap-x-7 sm:gap-y-0 sm:pl-7"
  >
    <router-link v-for="(navLink, i) in navigation" :key="i" class="navLink" :to="navLink.href">{{ navLink.name }}</router-link>
    <UserProfileDropdown />
  </div>
</template>
