<script setup lang="ts">
import { Bars3Icon } from '@heroicons/vue/24/outline'
import SidebarNav from './components/_sidebarNav.vue'
import ChevronRightIcon from '~icons/octicon/chevron-right-16'
import NavBar from '@/components/NavBar/index.vue'

useSeoMeta({
  title: 'Admin Dashboard',
})

definePage({
  redirect: '/admin/enterprise/awards/applications',
  alias: ['/admin/'],
  name: 'Admin Dashboard',
  meta: {
    layout: 'userDashboard',
  },
})

const { t } = useI18n()

const route = useRoute()
const router = useRouter()

const breadcrumbSegments = computed(() => {
  const pathSegments = route.path.split('/').filter(p => p)

  let segments = pathSegments.map((segment, index) => {
    const path = `/${pathSegments.slice(0, index + 1).join('/')}`
    const matchedRoute = router.getRoutes().find(r => r.path === path)
    const name = matchedRoute ? matchedRoute.meta.breadcrumbsName ?? matchedRoute.name : segment

    return {
      name,
      path,
    }
  })

  // Filter out empty names
  segments = segments.filter(segment => segment.name)

  return segments
})
</script>

<template>
  <div>
    <div>
      <input id="adminDrawer" type="checkbox" class="drawer-toggle" />
      <div class="drawer-content flex flex-col">
        <!-- Navbar -->
        <header class="inset-x-0 top-0 flex w-full flex-wrap border-b bg-white text-sm sm:flex-nowrap sm:justify-start lg:pl-64">
          <NavBar class="px-6 lg:px-3" />
        </header>
        <div class="inset-x-0 top-0 flex w-full flex-wrap border-b bg-white text-sm sm:flex-nowrap sm:justify-start lg:pl-64">
          <div class="flex w-full items-center p-4">
            <label class="text-gray-500 hover:text-gray-600 lg:hidden" for="adminDrawer" :aria-label="t('toggleNavigation')">
              <span class="sr-only">{{ t('adminSidebarNav.toggleNav') }}</span>
              <Bars3Icon class="size-5" />
            </label>

            <!-- Breadcrumb -->
            <ol class="flex min-w-0 items-center whitespace-nowrap max-xl:ml-3" :aria-label="t('breadcrumb')">
              <li
                v-for="(segment, i) in breadcrumbSegments" :key="i"
                class="flex items-center text-sm text-gray-800"
                :class="{ 'hidden md:flex': (i !== breadcrumbSegments.length - 1) }"
              >
                <ChevronRightIcon
                  v-if="i !== 0"
                  class="mx-3 hidden size-4 shrink-0 overflow-visible text-gray-400 md:flex"
                />
                <span class="capitalize" :class="{ 'font-semibold': i === (breadcrumbSegments.length - 1) }">{{ segment.name }}</span>
              </li>
            </ol>
            <!-- End Breadcrumb -->
          </div>
        </div>

        <!-- Page content here -->
        <div class="lg:pl-64">
          <main>
            <router-view v-slot="{ Component }">
              <component :is="Component" v-if="Component" :key="route.fullPath" />
            </router-view>
          </main>
        </div>
      </div>
      <div class="drawer-side-custom z-50 max-lg:drawer-side lg:visible">
        <label for="adminDrawer" class="drawer-overlay lg:hidden"></label>
        <div class="scrollbar-y fixed inset-y-0 left-0 w-64 overflow-y-auto border-r border-gray-200 bg-white pb-10 pt-7 lg:bottom-0 lg:right-auto">
          <SidebarNav />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.router-link-active {
  @apply bg-gray-100 text-primary;
}
.drawer-side-custom > *:not(.drawer-overlay) {
  @apply lg:translate-x-0;
}
</style>
