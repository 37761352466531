<script setup lang="ts">
import { PaperAirplaneIcon } from '@heroicons/vue/24/outline'
import { getNode } from '@formkit/core'
import { useI18n } from 'vue-i18n'
import FormSchemaEnterprise from './schemas/_formSchemaEnterprise.json'
import FormSchemaEnterpriseFr from './schemas/_formSchemaEnterpriseFr.json'
import FormSchemaOrganisation from './schemas/_formSchemaOrganisation.json'
import FormSchemaOrganisationFr from './schemas/_formSchemaOrganisationFr.json'
import { useCrud } from '@/composables/useCrud'
import type { Enterprise } from '@/types/commonTypes'

const utilitiesStore = useUtilitiesStore()
const authStore = useAuthStore()
const { user } = storeToRefs(authStore)

const { t, locale } = useI18n()

definePage({
  alias: ['/dashboard/my-organisation'],
  meta: {
    layout: 'userDashboard',
  },
})

useSeoMeta({
  title: () => authStore.isBdsUser ? t('seo.myOrganization.title') : t('seo.myEnterprise.title'),
  description: () => authStore.isBdsUser ? t('seo.myOrganization.description') : t('seo.myEnterprise.description'),
})

const getSectorsAPI = useCrud('sectors')
const createEnterpriseAPI = useCrud(`${authStore.entityApiPath}`)
const updateEnterpriseAPI = useCrud(`${authStore.entityApiPath}/my_enterprise`)

const state = reactive({
  sectors: [] as any[],
})

const updateSectors = async () => {
  state.sectors = await getSectorsAPI.getItem<any[]>()

  const node = getNode('sector')
  if (node) {
    node.props.options = state.sectors.map(m => ({
      value: m.identifier,
      label: m.name,
    }))
  }
}

onBeforeMount(async () => {
  await updateSectors()
})

watch(() => locale.value, async () => {
  await updateSectors()
})

const submitForm = (formData: Partial<Enterprise>) => {
  if (user.value.member_of_entity) {
    updateEnterpriseAPI.patchItem(formData).then((resp) => {
      authStore.user.member_of_entity = resp
      updateEnterpriseAPI.isLoading.value = false
      utilitiesStore.alertMessage = {
        text: t('enterpriseProfileChangesSaved'),
      }
    })
  }
  else {
    createEnterpriseAPI.postItem(formData).then((resp) => {
      authStore.user.member_of_entity = resp
      createEnterpriseAPI.isLoading.value = false
      utilitiesStore.alertMessage = {
        text: t('enterpriseProfileChangesSaved'),
      }
    })
  }
}

const getFormSchema = () => {
  if (authStore.isBdsUser)
    return locale.value === 'fr' ? FormSchemaOrganisationFr : FormSchemaOrganisation

  return locale.value === 'fr' ? FormSchemaEnterpriseFr : FormSchemaEnterprise
}

const getInvitesUrl = () => {
  return authStore.isBdsUser ? '/dashboard/my-organisation/invites' : '/dashboard/my-enterprise/invites'
}
</script>

<template>
  <div>
    <div class="border-b border-gray-200 bg-white px-4 py-5 xl:px-0">
      <div class="-ml-4 -mt-4 mb-12 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div class="ml-4">
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            {{
              authStore.isBdsUser
                ? t('enterpriseDashboard.myOrganisation.title') : t('enterpriseDashboard.myEnterprise.title')
            }}
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            {{
              authStore.isBdsUser
                ? t('enterpriseDashboard.myOrganisation.description') : t('enterpriseDashboard.myEnterprise.description')
            }}
          </p>
        </div>
        <div class="ml-4 shrink-0">
          <button
            type="button" class="btn btn-primary relative inline-flex items-center"
            @click="$router.push(getInvitesUrl())"
          >
            {{ t('manageInvitations.buttonText') }}
          </button>
        </div>
      </div>

      <FormKit
        v-if="user"
        type="form"
        :value="user.member_of_entity"
        :actions="false"
        @submit="submitForm"
      >
        <FormKitSchema :schema="getFormSchema()" />
        <div class="mx-auto text-right">
          <FormKit
            type="submit"
            :loading="createEnterpriseAPI.isLoading.value || updateEnterpriseAPI.isLoading.value"
          >
            {{ t('save') }}
            <PaperAirplaneIcon class="size-5" />
          </FormKit>
        </div>
      </FormKit>
    </div>
  </div>
</template>
