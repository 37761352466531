<script lang="ts" setup>
definePage({
  redirect: '/admin/enterprise/replicator/applications',
  alias: ['/admin/enterprise/replicator/'],
  name: 'Replicator',
  meta: {
    layout: 'userDashboard',
  },
})
</script>

<template>
  <div></div>
</template>
