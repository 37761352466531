import {
  type RouteRecordRaw,
  createMemoryHistory,
  createRouter,
  createWebHistory,
} from 'vue-router/auto'
import { setupLayouts } from 'virtual:generated-layouts'

import './axios'

// Configure axios interceptors
import NProgress from 'nprogress'
import type {
  NavigationFailure,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
} from 'vue-router'

const isTestEnv = import.meta.env.MODE === 'test'

function recursiveLayouts(route: RouteRecordRaw): RouteRecordRaw {
  if (route.children) {
    for (let i = 0; i < route.children.length; i++)
      route.children[i] = recursiveLayouts(route.children[i])

    return route
  }

  return setupLayouts([route as any])[0] as RouteRecordRaw
}

const router = createRouter({
  extendRoutes(routes: RouteRecordRaw[]) {
    // Apply layouts to each route
    return routes.map((route) => {
      return recursiveLayouts(route)
    })
  },
  scrollBehavior(to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, savedPosition) {
    if (to.hash)
      return document.querySelector(to.hash)?.scrollIntoView({ behavior: 'smooth' })

    if (savedPosition)
      return savedPosition
    else
      return { top: 0 }
  },
  history: isTestEnv ? createMemoryHistory() : createWebHistory(),
})

// Dynamic middleware loading
async function loadMiddleware(middlewareName: string) {
  return await import(`@/middlewares/${middlewareName}.ts`)
}

async function processMiddlewares(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  // Load middlewares
  const middlewaresToLoad: string[] = [...new Set((to.meta.middlewares as string[]) || [])]

  if (middlewaresToLoad.length === 0)
    return next()

  const middlewaresPromises = middlewaresToLoad.map(async (middlewareName) => {
    const middlewareModule = await loadMiddleware(middlewareName)

    const middlewareFunction = middlewareModule[middlewareName]
    return middlewareFunction(to, from)
  })

  try {
    await Promise.all(middlewaresPromises)
    next()
  }
  catch (error) {
    if (error instanceof Error)
      next(error.message || '/')
  }
}

// Apply dynamic middlewares to router navigation guards
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  // Use nprogress to show a progress bar
  if (to.path !== from.path && !isTestEnv)
    NProgress.start()

  // Load middlewares
  await processMiddlewares(to, from, next)
})

router.afterEach(() => {
  if (!isTestEnv)
    NProgress.done()
})

// Handle dynamic imports failing
router.onError((error: NavigationFailure, to: RouteLocationNormalized) => {
  if (import.meta.env.MODE !== 'development'
    && (error.message.includes('Failed to fetch dynamically imported module') || error.message.includes('Importing a module script failed')))
    window.location.href = to.fullPath
})

export default router
