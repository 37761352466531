<script setup lang="ts">
import { type FormKitFrameworkContext } from '@formkit/core'

const props = defineProps<{
  context: FormKitFrameworkContext
}>()

const value = computed({
  get: () => {
    return props.context?._value
  },
  set: (value) => {
    props.context?.node.input(value)
  },
})
</script>

<template>
  <input v-model="value" type="checkbox" class="toggle toggle-primary" />
</template>
