<script lang="ts" setup>
definePage({
  redirect: '/admin/enterprise/starter/applications',
  alias: ['/admin/enterprise/starter/'],
  name: 'Starter',
  meta: {
    layout: 'userDashboard',
  },
})
</script>

<template>
  <div></div>
</template>
