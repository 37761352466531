<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import { EditorContent, useEditor } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import IconUnderlined from '~icons/material-symbols/format-underlined-rounded'
import IconBold from '~icons/material-symbols/format-bold-rounded'
import IconItalic from '~icons/material-symbols/format-italic-rounded'
import IconStrikeThrough from '~icons/material-symbols/strikethrough-s-rounded'
import IconLink from '~icons/material-symbols/link-rounded'
import IconLinkOff from '~icons/material-symbols/link-off-rounded'
import IconNumberedList from '~icons/material-symbols/format-list-numbered-rtl-rounded'
import IconBulletinList from '~icons/material-symbols/format-list-bulleted-rounded'
import IconImage from '~icons/material-symbols/imagesmode-outline-rounded'
import IconH1 from '~icons/material-symbols/format-h1-rounded'
import IconH2 from '~icons/material-symbols/format-h2-rounded'
import IconH3 from '~icons/material-symbols/format-h3-rounded'
import IconQuote from '~icons/material-symbols/format-quote-rounded'
import IconHorizontalRule from '~icons/material-symbols/horizontal-rule-rounded'
import IconHardBreak from '~icons/material-symbols/insert-page-break-outline-rounded'
import IconUndo from '~icons/material-symbols/undo-rounded'
import IconRedo from '~icons/material-symbols/redo-rounded'

const props = defineProps<{
  context: FormKitFrameworkContext
}>()

const value = computed({
  get: () => {
    return props.context?._value
  },
  set: (value) => {
    props.context?.node.input(value)
  },
})

const placeholder = computed(() => {
  return props.context?.attrs.placeholder
})

const editor = useEditor({
  content: value.value,
  onUpdate: () => {
    const editorValue = editor.value?.getHTML()
    if (value.value !== editorValue)
      props.context?.node.input(editorValue)
  },
  editorProps: {
    attributes: {
      class: 'my-6 mx-auto focus:outline-none',
    },
  },
  extensions: [
    StarterKit,
    Placeholder.configure({
      placeholder: placeholder.value || 'Type something...',
    }),
    Underline,
    Link,
    Image,
  ],
})

watch(() => value.value, (newVal) => {
  if (newVal !== editor.value?.getHTML())
    editor.value?.commands.setContent(newVal, false)
})

const setLink = () => {
  if (editor.value) {
    const previousUrl = editor.value.getAttributes('link').href
    const url = (window as any).prompt('URL', previousUrl)

    // cancelled
    if (url === null)
      return

    // empty
    if (url === '') {
      editor.value
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run()

      return
    }

    // update link
    editor.value
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run()
  }
}

const addImage = () => {
  if (editor.value) {
    const url = (window as any).prompt('URL')

    if (url)
      editor.value.chain().focus().setImage({ src: url }).run()
  }
}
</script>

<template>
  <div v-if="editor" class="editorWrapper card card-bordered overflow-hidden border-gray-200">
    <div>
      <div class="flex gap-x-0.5 border-b border-gray-200 p-2 align-middle">
        <button
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          :class="{ 'is-active': editor.isActive('bold') }" type="button"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <IconBold class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          :class="{ 'is-active': editor.isActive('italic') }" type="button"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <IconItalic class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().toggleUnderline().run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          :class="{ 'is-active': editor.isActive('underline') }" type="button"
          @click="editor.chain().focus().toggleUnderline().run()"
        >
          <IconUnderlined class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().toggleStrike().run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          :class="{ 'is-active': editor.isActive('strike') }" type="button"
          @click="editor.chain().focus().toggleStrike().run()"
        >
          <IconStrikeThrough class="size-5 shrink-0" />
        </button>
        <button
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          :class="{ 'is-active': editor.isActive('link') }" type="button"
          @click="setLink"
        >
          <IconLink class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.isActive('link')"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          type="button"
          @click="editor.chain().focus().unsetLink().run()"
        >
          <IconLinkOff class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().toggleOrderedList().run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          :class="{ 'is-active': editor.isActive('ordered_list') }" type="button"
          @click="editor.chain().focus().toggleOrderedList().run()"
        >
          <IconNumberedList class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().toggleBulletList().run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          :class="{ 'is-active': editor.isActive('bullet_list') }" type="button"
          @click="editor.chain().focus().toggleBulletList().run()"
        >
          <IconBulletinList class="size-5 shrink-0" />
        </button>

        <button
          type="button"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          @click="addImage"
        >
          <IconImage class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().toggleHeading({ level: 1 }).run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" type="button"
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        >
          <IconH1 class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().toggleHeading({ level: 2 }).run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" type="button"
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        >
          <IconH2 class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().toggleHeading({ level: 3 }).run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }" type="button"
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          <IconH3 class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().toggleBlockquote().run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          :class="{ 'is-active': editor.isActive('blockquote') }" type="button"
          @click="editor.chain().focus().toggleBlockquote().run()"
        >
          <IconQuote class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().setHorizontalRule().run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          type="button"
          @click="editor.chain().focus().setHorizontalRule().run()"
        >
          <IconHorizontalRule class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().setHardBreak().run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          type="button"
          @click="editor.chain().focus().setHardBreak().run()"
        >
          <IconHardBreak class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().undo().run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          type="button"
          @click="editor.chain().focus().undo().run()"
        >
          <IconUndo class="size-5 shrink-0" />
        </button>
        <button
          :disabled="!editor.can().chain().focus().redo().run()"
          class="inline-flex size-8 items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50"
          type="button"
          @click="editor.chain().focus().redo().run()"
        >
          <IconRedo class="size-5 shrink-0" />
        </button>
      </div>

      <EditorContent v-model="value" :editor="editor" />
    </div>
  </div>
</template>

<style lang="scss">
.editorWrapper {
  .is-active {
    background-color: #f3f4f6; /* Adjust the color to match your active style */
  }
  .ProseMirror:focus {
    outline: none;
  }

  .ProseMirror {
    @apply mx-4 min-h-40
  }

  hr.ProseMirror-selectednode {
    border-top: 1px solid #68CEF8;
  }

  .tiptap {
    ul p, ol p {
      padding: 0 1rem;
    }
    img {
      max-width: 100%;
      height: auto;

      &.ProseMirror-selectednode {
        outline: 3px solid #68CEF8;
      }
    }

    blockquote {
      padding-left: 1rem;
      border-left: 3px solid rgba(#0D0D0D, 0.1);
    }
  }

  .tiptap p.is-editor-empty:first-child::before {
    font-size: 14px;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
</style>
