<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'
import InputTags from '@/components/InputTags.vue'

const props = defineProps<{
  context: FormKitFrameworkContext
}>()

const value = computed({
  get: () => {
    return props.context?._value
  },
  set: (value) => {
    props.context?.node.input(value)
  },
})
</script>

<template>
  <InputTags
    v-model="value"
  />
</template>
