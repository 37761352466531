import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'
import { changeLocale } from '@formkit/vue'

function setupI18n(defaultLanguage = '') {
  /*
   * All i18n resources specified in the plugin `include` option can be loaded
   * at once using the import syntax
   */

  // Supported locales are the keys of your messages object
  const supportedLocales = Object.keys(messages as Record<string, any>)

  // Extract the browser's preferred language
  const browserLocale = navigator.language.split('-')[0]

  // Check if the browser's preferred language is supported
  const locale = supportedLocales.includes(browserLocale) ? browserLocale : 'en'

  changeLocale(defaultLanguage || locale)

  return createI18n({
    warnHtmlMessage: false,
    legacy: false,
    globalInjection: true,
    fallbackWarn: false,
    locale: defaultLanguage || locale,
    fallbackLocale: 'en',
    messages,
  })
}

export const i18n = setupI18n
