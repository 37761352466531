<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from '@heroicons/vue/24/solid'

const utilitiesStore = useUtilitiesStore()
const { alertMessage } = storeToRefs(utilitiesStore)

const timeoutId = ref<any>(null)

watch(alertMessage, (newVal) => {
  if (newVal) {
    // Clear existing timeout
    if (timeoutId.value)
      clearTimeout(timeoutId.value)

    // Set new timeout
    timeoutId.value = setTimeout(() => {
      utilitiesStore.alertMessage = null
    }, 10000)
  }
})
</script>

<template>
  <teleport to="body">
    <Transition name="slide-alert">
      <div
        v-if="alertMessage"
        id="globalAlert"
        role="alert"
        class="fixed bottom-20 z-[100] w-full max-w-[450px] rounded-lg bg-white shadow-2xl sm:right-4 sm:w-1/2 xl:w-1/4"
      >
        <div
          class="relative flex items-center overflow-hidden rounded-lg px-2 py-6"
          :data-test-id="alertMessage.error ? 'error' : 'success'"
          :class="{
            'bg-error/20': alertMessage.error,
            'bg-success/20': !alertMessage.error,
          }"
        >
          <button
            class="btn btn-link btn-xs mr-2 px-0"
            @click="utilitiesStore.alertMessage = null"
          >
            <XCircleIcon
              class="size-6 text-secondary/50 transition-colors hover:text-secondary/100"
            />
          </button>
          <div class="absolute -right-10 -top-10">
            <ExclamationTriangleIcon
              v-if="alertMessage.error"
              class="size-36 shrink-0 fill-error opacity-30"
            />
            <CheckCircleIcon
              v-else
              class="size-36 shrink-0 fill-success opacity-30"
            />
          </div>
          <p class="font-medium text-secondary/90">
            {{ alertMessage.text }}
          </p>
        </div>
      </div>
    </Transition>
  </teleport>
</template>
