import { defineStore } from 'pinia'
import type { Country } from '@/types/commonTypes'
import { useCrud } from '@/composables/useCrud'

export const useCountriesStore = defineStore('CountriesStore', {
  state: () => ({
    countries: [] as Country[],
  }),
  getters: {
    findCountries: state => (identifiers: string[]) => {
      return state.countries
        .filter(country => identifiers.includes(country.identifier))
        .map(country => country.name)
        .join(', ')
    },
  },
  actions: {
    async getCountries() {
      const countriesAPI = useCrud('countries')

      this.countries = await countriesAPI.getItem<Country[]>() as Country[]
    },
  },
})
