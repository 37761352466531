<script lang="ts" setup>
import OwnerInvitations from './components/_ownerInvitations.vue'
import UserInvitations from './components/_userInvitations.vue'

const { t } = useI18n()

definePage({
  alias: ['/dashboard/my-organisation/invites'],
  meta: {
    layout: 'userDashboard',
  },
})

useSeoMeta({
  title: () => t('seo.manageInvites.title'),
  description: () => t('seo.manageInvites.description'),
})

const authStore = useAuthStore()
const { user } = storeToRefs(authStore)
</script>

<template>
  <div>
    <OwnerInvitations v-if="user.owner_of_entity" />
    <UserInvitations v-else />
  </div>
</template>
