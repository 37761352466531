export function handleApiError(error: any): string {
  let errorMessage = 'Something went wrong. Please try again later.'

  if (error.response) {
    switch (error.response.status) {
      case 400:
        errorMessage
          = 'There seems to be a problem with your request. Please check your input and try again.'
        break
      case 401:
        errorMessage
          = 'It appears you might not be logged in. Please log in and try again.'
        break
      case 403:
        errorMessage
          = 'Unfortunately, you don\'t have permission to perform this action.'
        break
      case 404:
        errorMessage
          = 'We\'re sorry, but we can\'t find the page or resource you\'re looking for.'
        break
      case 429:
        errorMessage
          = 'You\'re making too many requests too quickly. Please slow down and try again later.'
        break
      case 500:
      case 502:
      case 503:
      case 504:
        errorMessage
          = 'Something went wrong on our end. We\'re working to fix this, so please try again later.'
        break
    }

    const serverError
      = error.response?.data?.message
      ?? error.response?.data?.detail
      ?? error.response?.data?.error

    if (serverError && typeof serverError === 'string')
      errorMessage = serverError
    else if (serverError && Array.isArray(serverError))
      errorMessage = serverError[0]
  }
  else if (error.request) {
    errorMessage
      = 'It seems that we\'re having trouble reaching the server. Please check your internet connection and try again.'
  }
  else if (error.message && import.meta.env.MODE !== 'production') {
    errorMessage = error.message
  }
  else {
    errorMessage = 'Unexpected error occurred. Please try again later.'
  }

  return errorMessage
}
