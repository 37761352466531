<script lang="ts" setup>
import logo from '@/assets/img/logo/Logo_white_title_only.svg'
import DarkLogo from '@/assets/img/logo/Logo_red_title_only.svg'

const props = defineProps<{
  dark?: boolean
}>()

const authStore = useAuthStore()
</script>

<template>
  <div
    class="shrink-0 cursor-pointer"
    @click="authStore.redirectUser()"
  >
    <img :src="props.dark ? DarkLogo : logo" class="h-[40px] w-[110px]" alt="SEED logo" />
  </div>
</template>
