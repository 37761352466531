<script setup lang="ts">
const { t } = useI18n()
const getCopyrightYears = (baseYear: number) => {
  const currentYear = new Date().getFullYear()
  return currentYear > baseYear ? `${baseYear} - ${currentYear}` : `${baseYear}`
}
</script>

<template>
  <footer
    class="w-full border-t border-t-gray-200 py-10"
  >
    <div class="mx-auto w-full max-w-7xl text-center">
      <div class="mt-3">
        <p class="text-gray-500">
          &copy; {{ getCopyrightYears(2023) }} {{ t('footer.copyright') }}
        </p>
      </div>

      <div class="mt-3 space-x-2">
        <ul class="text-center">
          <li
            class="relative inline-block pr-8 before:absolute before:right-3 before:top-1/2 before:-translate-y-1/2 before:text-gray-300 before:content-['/'] last:pr-0 last-of-type:before:hidden"
          >
            <router-link
              to="/privacy-policy"
              class="inline-flex gap-x-2 text-sm"
            >
              {{ t('cookiesConsent.privacyPolicy') }}
            </router-link>
          </li>
          <li
            class="relative inline-block pr-8 before:absolute before:right-3 before:top-1/2 before:-translate-y-1/2 before:text-gray-300 before:content-['/'] last:pr-0 last-of-type:before:hidden"
          >
            <router-link
              class="inline-flex gap-x-2 text-sm"
              to="/legal-notice"
            >
              {{ t('footer.legalNotice') }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
