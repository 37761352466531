<script setup lang="ts">
import { UserCircleIcon } from '@heroicons/vue/24/solid'
import { onClickOutside } from '@vueuse/core'

const userDropdown = ref<HTMLDetailsElement | null>(null)

const authStore = useAuthStore()
const { userName } = storeToRefs(authStore)

const { t } = useI18n()

const closeDropDown = () => {
  (userDropdown.value as HTMLDetailsElement).open = false
}

onClickOutside(
  userDropdown,
  () => closeDropDown(),
)
</script>

<template>
  <div class="flex items-center gap-x-2 sm:ml-auto">
    <details ref="userDropdown" class="dropdown dropdown-end">
      <summary class="avatar btn btn-ghost mx-0 border-none px-0">
        <span class="navLink flex items-center font-medium capitalize text-gray-300 ">
          {{ userName ?? 'User' }}
          <UserCircleIcon class="ml-2 w-10 rounded-full fill-white" />
        </span>
      </summary>
      <ul class="menu dropdown-content w-52 rounded-box bg-base-100 p-2 shadow">
        <li><button class="btn-ghost" @click="$router.push('/settings/profile'), closeDropDown()">{{ t('settings') }}</button></li>
        <li><button class="btn-ghost" @click="authStore.logout()">{{ t('userProfileDropdown.logout') }}</button></li>
      </ul>
    </details>
  </div>
  <LangSwitcher />
</template>
