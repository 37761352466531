import { createHead } from '@unhead/vue'
import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import './assets/css/index.scss'
import { isAxiosError } from 'axios'

// Formkit
import { defaultConfig, plugin } from '@formkit/vue'

// Matomo analytics
import VueMatomo from 'vue-matomo'

// Common
import App from '@/App.vue'
import formkitConfig from '@/formkit/formkit.config'
import { initUser, pinia } from '@/store/store'
import { i18n } from '@/i18n/i18n'
import router from '@/router/router'
import { useLanguageStore } from '@/store/languageStore'

const app = createApp(App)

app.use(pinia)

// Sentry
const utilitiesStore = useUtilitiesStore()
const languageStore = useLanguageStore()

if (import.meta.env.MODE !== 'development') {
  /*
   * TODO: Sentry configuration is not working as expected.
   * This configuration includes exclusions for various common user errors and network errors (most common errors being caught),
   * however, these exclusions are not working as expected as these errors are still being caught by Sentry.
   * This should be fixed in the future.
   */
  Sentry.init({
    app,
    environment: utilitiesStore.isStaging ? 'staging' : import.meta.env.MODE,
    release: utilitiesStore.version,
    dsn: 'https://7b036b26ed3d4f15ab2e7fee2558f8f5@sentry.adelphi.app/8',
    normalizeDepth: 11,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.05,
    tracePropagationTargets: ['localhost', 'staging.platform.seed.uno', 'platform.seed.uno'],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const error = hint?.originalException

      if (error && isAxiosError(error)) {
        // Now TypeScript knows error is an AxiosError
        if (error.message === 'Network Error')
          return null // Don't send the event for network errors

        const statusCode = error.response?.status
        if ([400, 401, 403, 404].includes(statusCode ?? 0))
          return null // Don't send the event for these status codes
      }

      return event // Send the event for all other errors
    },
  })
}

// Get user data before anything else
initUser().then(() => {
  app.use(plugin, defaultConfig(formkitConfig))
  app.use(i18n(languageStore.currentLanguage))
  app.use(router)
  app.use(createHead())

  if (import.meta.env.MODE === 'production' && !utilitiesStore.isStaging) {
    app.use(VueMatomo, {
      host: 'https://matomo.adelphi.de',
      siteId: 158,
      requireConsent: true,
      router,
    })
  }

  app.mount('#app')
})
