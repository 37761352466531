import type { DefaultConfigOptions } from '@formkit/vue'

import { check, close, fileDoc } from '@formkit/icons'
import { generateClasses } from '@formkit/themes'
import {
  // createAutoAnimatePlugin,
  createFloatingLabelsPlugin,
  createMultiStepPlugin,
} from '@formkit/addons'
import '@formkit/addons/css/floatingLabels'
import { createInput } from '@formkit/vue'
import { en, fr } from '@formkit/i18n'
import Cookies from 'js-cookie'
import formkitTailwindTheme from './formkitStyles'
import { wordCount } from './formkitRules'
import { createLoadingSpinnerPlugin } from './formkitPlugins'
import CountrySelect from '@/components/FormKit/FormKitCountrySelect.vue'
import AutoComplete from '@/components/FormKit/FormKitComboBox.vue'
import Toggle from '@/components/FormKit/FormKitToggle.vue'
import Editor from '@/components/FormKit/FormKitEditor.vue'
import '@formkit/addons/css/multistep'
import InputTags from '@/components/FormKit/FormKitInputTags.vue'

const config: DefaultConfigOptions = {
  locales: { en, fr },
  locale: Cookies.get('appLanguage') || 'en',
  config: {
    classes: generateClasses(formkitTailwindTheme),
  },
  plugins: [
    // createAutoAnimatePlugin(),
    createFloatingLabelsPlugin({
      useAsDefault: false, // defaults to false
    }),
    createLoadingSpinnerPlugin(),
    createMultiStepPlugin(),
  ],
  inputs: {
    country: createInput(CountrySelect),
    autoComplete: createInput(AutoComplete),
    toggle: createInput(Toggle),
    editor: createInput(Editor),
    inputTags: createInput(InputTags),
    // stepNumber,
  },
  icons: {
    check,
    fileDoc,
    close,
  },
  rules: {
    wordCount,
  },
  messages: {
    en: {
      validation: {
        required() {
          return 'This field is required!'
        },
        wordCount: ({ args }) => {
          return `Please stick to ${args} words!`
        },
      },
    },
    fr: {
      validation: {
        required() {
          return 'Ce champ est obligatoire!'
        },
        wordCount: ({ args }) => {
          return `Veuillez vous en tenir à ${args} mots!`
        },
      },
    },
  },
}

export default config
