<script lang="ts" setup>
const authStore = useAuthStore()
const { t } = useI18n()
</script>

<template>
  <div
    class="mt-5 flex flex-col justify-end gap-x-0 gap-y-4 sm:mt-0 sm:flex-row sm:items-center sm:gap-x-7 sm:gap-y-0 sm:pl-7"
  >
    <button class="navLink" @click="authStore.logout()">{{ t('userProfileDropdown.logout') }}</button>
    <LangSwitcher />
  </div>
</template>
