<script setup lang="ts">
import { FormKit } from '@formkit/vue'
import type { FormKitFrameworkContext } from '@formkit/core'
import { useCountriesStore } from '@/store/countriesStore'

interface propsType {
  multiple?: boolean
}

const props = defineProps<{
  context: FormKitFrameworkContext
}>()

const { locale } = useI18n()

const propItems = ref(props.context.attrs) as Ref<propsType>

const value = computed({
  get: () => {
    return props.context?._value
  },
  set: (value) => {
    props.context?.node.input(value)
  },
})

const countriesStore = useCountriesStore()
const { countries } = storeToRefs(countriesStore)

onBeforeMount(async () => {
  await countriesStore.getCountries()
})

watch(() => locale.value, async () => {
  await countriesStore.getCountries()
})
</script>

<template>
  <FormKit
    v-if="countries.length > 0"
    v-model="value"
    type="autoComplete"
    :multiple="propItems.multiple || false"
    :items="countries"
    :fields="['name']"
    :classes="{
      outer: '!mb-0',
      input: !value ? 'selectPlaceholder' : '',
    }"
    extract-column="identifier"
  />
</template>
