import { defineStore } from 'pinia'
import Cookies from 'js-cookie'
import { changeLocale } from '@formkit/vue'

export const useLanguageStore = defineStore('language', {
  state: () => ({
    currentLanguage: Cookies.get('appLanguage') || 'en',
  }),
  actions: {
    setLanguage(lang: string) {
      Cookies.set('appLanguage', lang, { expires: 365, SameSite: 'Strict' })
      this.currentLanguage = lang
      changeLocale(lang)
    },
  },
})
