import axios, { type AxiosResponse } from 'axios'
import { apiURL } from '@/router/axios'
import { handleApiError } from '@/router/errorHandler'

export function useCrud(url: string) {
  const utilitiesStore = useUtilitiesStore()
  const isLoading = ref(false)
  const errors = ref<Record<string, string[]>>({})

  const catchError = (error: any, updateError = true) => {
    if (updateError) {
      const errorData = error.response?.data
      if (errorData?.extra?.fields && Object.keys(errorData?.extra?.fields).length > 0)
        errors.value = errorData?.extra?.fields
    }

    utilitiesStore.alertMessage = {
      text: handleApiError(error),
      error: true,
    }
    isLoading.value = false
  }

  const postItem = async <T>(
    data: any = null,
    config = {},
  ): Promise<T> => {
    try {
      isLoading.value = true
      errors.value = {}
      const response: AxiosResponse<T> = await axios.post(
        `${apiURL}${url}/`,
        data,
        config,
      )
      return response.data as T
    }
    catch (error: any) {
      catchError(error)
      throw error
    }
  }

  const getItem = async <T>(params?: any, config = {}): Promise<T> => {
    try {
      isLoading.value = true
      const response: AxiosResponse<T> = await axios.get(`${apiURL}${url}/`, { params, ...config })
      return response.data as T
    }
    catch (error: any) {
      if (error?.response?.status !== 404) {
        utilitiesStore.alertMessage = {
          text: handleApiError(error),
          error: true,
        }
      }
      isLoading.value = false
      throw error
    }
  }

  const updateItem = async <T>(
    data?: any,
    additionalUrl = '',
  ): Promise<T> => {
    try {
      isLoading.value = true
      errors.value = {}
      const response: AxiosResponse<T> = await axios.put(
          `${apiURL}${url}${additionalUrl}/`,
          data,
      )
      return response.data as T
    }
    catch (error: any) {
      catchError(error)
      throw error
    }
  }

  const patchItem = async <T>(
    data?: any,
    additionalUrl = '',
  ): Promise<T> => {
    try {
      isLoading.value = true
      errors.value = {}
      const response: AxiosResponse<T> = await axios.patch(
        `${apiURL}${url}${additionalUrl}/`,
        data,
      )
      return response.data as T
    }
    catch (error: any) {
      catchError(error)
      throw error
    }
  }

  const deleteItem = async (
    additionalUrl = '',
    data?: any,
  ): Promise<boolean> => {
    try {
      isLoading.value = true
      await axios.delete(`${apiURL}${url}${additionalUrl}/`, { data })
      return true
    }
    catch (error: any) {
      catchError(error, false)
      throw error
    }
  }

  return {
    isLoading,
    errors,
    getItem,
    postItem,
    updateItem,
    patchItem,
    deleteItem,
  }
}
